import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class Terms extends Component {
  render() {
    return (
      <>
        {/* <Helmet>
          <title>Pet Medi Web - Terms Page</title>
          <meta
            name="description"
            content="Learn more about Pet Medi, your trusted pet health partner."
          />
          <meta
            name="keywords"
            content="pet, health, care, dogs, cats, veterinary,Pet clinic in Sri Lanka,Veterinary services Sri Lanka,Pet hospital,Affordable pet care Sri Lanka, 
      Veterinarian near me,Animal clinic Sri Lanka,Pet surgery ,Pet vaccinations Sri Lanka,24/7 emergency vet Sri Lanka, Pet health checkup Sri Lanka, Dog clinic Sri Lanka, 
      Cat veterinary care Sri Lanka, Exotic pet care Sri Lanka, Pet dental care Sri Lanka, Pet grooming, Pet boarding services Sri Lanka, Best vet for pet Sri Lanka, 
      Cataract surgery for pets Sri Lanka, Animal rescue services Sri Lanka ,Veterinary clinic , Sri Lanka , Pet Medi, pet, P , Sri Lanka Pets, Pet Care, Animal Clinic, 
      Vet, Pet Health, Pet Health Checkup, veterinary,Pet dental care, Grooming, Best Animal Clinic, Pet health monitoring app, Veterinary telemedicine Sri Lanka,Pet prescription management,
      Pet weight management Sri Lanka,Pet nutrition advice Sri Lanka,Digital veterinary solutions,Pet clinic appointment system,Senior pet care services,Specialized pet care Sri Lanka,
      Online vet consultation Sri Lanka,Pet illness prevention,Remote vet consultations,Pet healthcare software,Preventive vet care Sri Lanka,Pet emergency hotline Sri Lanka,
      Advanced pet diagnostics,Pet care plans Sri Lanka,In-home veterinary services,Pet surgery aftercare Sri Lanka,Pet grooming and spa Sri Lanka,Veterinary lab testing Sri Lanka,
      Personalized pet healthcare plans,Vet treatment packages Sri Lanka,Animal health solutions Sri Lanka,Vet treatment plans Sri Lanka,Pet physiotherapy Sri Lanka, 
      Vets near me, cat clinic near me, dog clinic near me, rabbit consultant doctors, dog consultant doctors, cat consultant doctors, online pet app, pet app online, 
      app for pets, app for animals, animal doctor, pet doctor, pet consultant, Pet wellness programs Sri Lanka, Pet wellness programs, Veterinary nutrition consultation, 
      Pet behavior training Sri Lanka, Pet behavior training, Veterinary dermatology Sri Lanka, Veterinary dermatology, Puppy vaccinations, Puppy vaccinations Sri Lanka, 
      Kitten vaccinations Sri Lanka, Kitten vaccinations, Flea and tick prevention Sri Lanka, Flea and tick prevention, Microchipping services Sri Lanka, Pet allergy treatment Sri Lanka, Pet allergy treatment,
      Veterinary orthopedic surgery Sri Lanka,Veterinary orthopedic surgery, 24-hour vet service Sri Lanka, 24-hour vet service , Emergency pet care Sri Lanka, Emergency pet care,
      Animal eye care Sri Lanka,Animal eye care,Pet dental cleaning Sri Lanka,Pet dental cleaning,Dog vaccinations Sri Lanka, Dog vaccinations,Cat vaccinations Sri Lanka,Cat vaccinations,Animal diagnostic imaging,
      Vet appointment booking online Sri Lanka,Vet appointment booking online,Pet Surgery Sri Lanka, Pet Surgery,Specialist vet services Sri Lanka,Specialist vet services,Pet cardiologist Sri Lanka, Pet cardiologist,
      Best dog vet Sri Lanka,Best dog vet, Best cat vet Sri Lanka, Best cat vet,Pet health checkup Sri Lanka, Pet health checkup, Puppy health checkup Sri Lanka,Puppy health checkup, Kitten health checkup Sri Lanka,Kitten health checkup,
      Emergency pet clinic near me,Pet health and wellness Sri Lanka,Pet health and wellness , pet treatment,Pet Health, Pet Services,Pet Services near me,Pet health diagnosis,Veterinary diagnosis,Common pet diseases,Animal health conditions,
      Dog illness diagnosis,Cat health problem, Dog health problem, Pet health problem, Pet disease symptoms, Veterinary medical conditions, Signs of illness in pets, Pet diagnostic tests,
      Dog allergy diagnosis, Cat urinary tract infection symptoms, Dog urinary tract infection symptoms, Pet urinary tract infection symptoms, Veterinary blood tests for pets, Pet arthritis treatment,
      Dog diabetes diagnosis, Cat diabetes diagnosis, Pet diabetes diagnosis, Pet cancer signs, Common pet skin diseases, Animal ear infections, Dog digestive problems diagnosis,
      Cat digestive problems diagnosis, Pet digestive problems diagnosis, Pet liver disease symptoms, Kidney disease in cats, Kidney disease in dogs, Kidney disease in pets,
      Pet obesity treatment, Dog heart disease symptoms, Cat heart disease symptoms, Pet heart disease symptoms, Cat thyroid disease, Dog thyroid disease, Pet thyroid disease,
      Pet parasite infections, Veterinary checkup for pets, Dog hip dysplasia diagnosis, Cat respiratory infections, Dog respiratory infections, Pet respiratory infections, Pancreatitis in pet symptoms, Dog Food, Cat Food"
    />
        </Helmet> */}

        <div className="pt-20 px-6 xl:px-16 md:px-12 lg:px-14 pb-20">
          <h2 className="text-xl font-bold mb-4">Terms and Conditions</h2>

          {/* 1 */}
          <p>
            <span className="font-semibold">1. Acceptance of Terms</span>
            <br />
            By registering and using the PetMedi application, you agree to be
            bound by these Terms and Conditions. PetMedi reserves the right to
            update or modify these Terms at any time by posting the revised
            Terms on the Application and notifying you via email. Your continued
            use of the Application after such revisions constitutes your
            acceptance of the updated Terms.
          </p>
          <br />

          {/* 2 */}
          <p>
            <span className="font-semibold">2. User Obligations</span>
            <br />
            <span>
              Account Registration: PetMedi is intended for use by authorized
              representatives of registered pet clinics and channeling centers.
              Only authorized representatives with the necessary permissions can
              access and use the application.
            </span>
            <br />
            <br />
            <span>
              Accurate Information: You agree to provide accurate and complete
              information during registration, including company name, address,
              email address, contact number, and any additional details
              requested for customization.
            </span>
            <br />
            <br />
            <span>
              Account Security: You are responsible for maintaining the
              confidentiality of your login credentials and for all activity
              that occurs under your account.
            </span>
            <br />
            <br />
            <span>
              Admin and User Management: A designated administrator ("Admin") of
              the registered company can manage user accounts and system access
              within the company. If the Admin's email address differs from the
              company's main email address, both email addresses must be
              provided during registration since All important communication
              regarding the system will be sent to the Admin email address and
              the Admin serves as the primary contact person and is responsible
              for the system's use within the company.
            </span>
            <br />
            <br />
            <span>
              Acceptable Use: You agree to use the Application in a lawful and
              ethical manner, and not for any purpose that violates these Terms,
              applicable laws, or the rights of others.
            </span>{" "}
            <br />
            <br />
            <span>
              Prohibited activities include: Transmit any illegal, harmful or
              ethnically offensive material.Interfere with or disrupt the
              servers or networks connected to the Application. Attempt to gain
              unauthorized access to the Application or any other
              accounts.Violate any applicable laws or regulations. Use the
              application in a way that harms animal welfare.
            </span>
            <br />
          </p>
          <br />

          {/* 3 */}
          <p>
            <span className="font-semibold">3. Privacy Policy</span>
            <br />
            PetMedi respects your privacy. Please refer to our separate Privacy
            Policy for details on how we collect, use, and protect your personal
            data. The Privacy Policy is incorporated by reference into these
            Terms.
          </p>
          <br />

          {/* 4 */}
          <p>
            <span className="font-semibold">
              4. Subscription Plans and Payment
            </span>
            <br />
            <span>
              Free Trial: We offer a 30-day free trial of the Application to
              allow you to experience its features. No prior approval is needed
              to access the free trial. However, providing valid Visa/MasterCard
              details during registration is required for verification purposes.
            </span>
            <br />
            <br />
            <span>
              Paid Subscriptions: We offer various subscription plans with
              different features and pricing. You can select a paid plan during
              registration or upgrade your plan at any time.
            </span>
            <br />
            <br />
            <span>
              Payment Methods: We accept payments of subscription fees via Visa
              and MasterCard or or other approved payment method. Your card will
              be charged immediately upon upgrading to a paid plan. You will
              receive a payment confirmation email.
            </span>
            <br />
            <br />
            <span>
              Subscription Activation: After the expiration of your free
              subscription plan, you can choose another paid subscription plan.
              Upgrading to a paid plan will result in immediate charges, and
              your billing cycle will start on the day of upgrade. You will
              receive a payment receipt via email.
            </span>
            <br />
            <br />
            <span>
              Plan Changes: Companies have the option to choose a paid
              subscription plan during registration. However, the chosen paid
              plan will only become active after the free trial period has
              ended. You can change your plan after the expiration of your
              current plan period.
            </span>
            <br />
            <br />
            <span>
              Approval Process: We may implement an approval process for paid
              subscriptions. After your free trial ends, PetMedi will review
              your company details before activating a paid plan. Based on this
              review, your company may be either approved or rejected. You will
              be notified of the decision (approval or rejection) via email.
              Only approved companies will be able to proceed with using the
              system under a paid plan.
            </span>
            <br />
            <br />
            <span>
              Refunds: We process refunds according to our Fair Refund Policy,
              which is available upon request. Generally, refunds are not
              offered for unused portions of your subscription.
            </span>
          </p>
          <br />

          {/* 5 */}
          <p>
            <span className="font-semibold">5. Customization</span>
            <br />
            We offer customization options to tailor the Application to your
            company's needs. You can provide metadata during registration, such
            as your company logo, to personalize the user interface.
          </p>
          <br />

          {/* 6 */}
          <p>
            <span className="font-semibold">6. Intellectual Property</span>
            <br />
            The Application, its content, and all intellectual property rights
            associated with them (including trademarks, copyrights, and patents)
            belong to PetMedi or its licensors. You are granted a non-exclusive,
            non-transferable license to use the Application for your internal
            business purposes only, in accordance with these Terms.
          </p>
          <br />

          {/* 7 */}
          <p>
            <span className="font-semibold">7. Termination</span>
            <br />
            <span>
              User Termination: You may terminate your account and subscription
              at any time by contacting us. Your account will be closed, and you
              will no longer have access to the Application. However, you remain
              responsible for any outstanding subscription fees.
            </span>
            <br />
            <br />
            <span>
              PetMedi Termination: PetMedi may terminate your account and access
              to the Application for any reason, including, but not limited to,
              a violation of these Terms. We will use commercially reasonable
              efforts to notify you by email before terminating your account,
              except in urgent circumstances.
            </span>
            <br />
            <br />
            <span>
              Inactive Accounts: We will send you reminder emails before your
              subscription expires and again on the expiration date itself.
              However, If your subscription expires and you haven't chosen a new
              plan, we'll grant you a 15-day grace period to activate a new one.
              During this time, your account will be frozen and access will be
              restricted. Accounts that remain frozen for an extended period may
              be subject to auto-cancellation as per our Cancellation Policy.
            </span>
            <br />
          </p>
          <br />

          {/* 8 */}
          <p>
            <span className="font-semibold">8. Disclaimer</span>
            <br />
            The Application is provided "as is" and without warranties of any
            kind, express or implied. PetMedi disclaims all warranties,
            including, but not limited to, warranties of merchantability,
            fitness for a particular purpose, and non-infringement. PetMedi does
            not warrant that the Application will be uninterrupted or
            error-free, that defects will be corrected, or that the Application
            or the server that makes it available are free of viruses or other
            harmful components.
          </p>
          <br />

          {/* 9 */}
          <p>
            <span className="font-semibold">9. Modifications to the Terms</span>
            <br />
            We reserve the right to update or modify these Terms at any time. We
            will notify you of any material changes by email or by posting a
            notice on the Application. Your continued use of the Application
            after any such changes constitutes your acceptance of the new Terms.
          </p>
          <br />

          {/* 10 */}
          <p>
            <span className="font-semibold">10. Internet delays</span>
            <br />
            Our app is subject to limitations, delays, and other problems
            inherent in the use of the internet and electronic communications.
            We are not responsible for any delays, service disruptions, or other
            damage resulting from such problems.
          </p>
          <br />
        </div>
      </>
    );
  }
}
