import React, { useEffect, useRef, useState } from "react";

const petAges = [
    { name: '1-3 Years' },
    { name: '3-5 Years'},
    { name: '5-8 Years'},
];


const FilterAgeBar = () => {
    const [listHeight, setListHeight] = useState('auto');
    const listRef = useRef<HTMLUListElement>(null);

    // State to manage which category's checkbox is active
    const [activeCategories, setActiveCategories] = useState<{ [key: number]: boolean }>({});

    useEffect(() => {
        if (listRef.current) {
            const height = listRef.current.scrollHeight;
            setListHeight(height > 420 ? '420px' : `${height}px`);
        }
    }, [petAges]);

    const handleCheckboxChange = (index: number) => {
        setActiveCategories((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    return (
        <div className="pt-6">
            <div className="overflow-hidden rounded-2xl shadow-md w-full md:w-52">
                <ul ref={listRef} className="bg-white p-2 w-full overflow-y-auto scrollbar-thin scrollbar-thumb-blue-700 scrollbar-track-white" style={{ height: listHeight }}>
                    {petAges.map((age, index) => (
                        <li key={index} className="py-1 flex items-center">
                            <button
                                type="button"
                                className="flex items-center p-2 hover:bg-blue-100 rounded-md w-full text-left"
                                onClick={() => handleCheckboxChange(index)}
                            >
                            <input
                                type="checkbox"
                                checked={!!activeCategories[index]}
                                onChange={() => handleCheckboxChange(index)}
                                className="mr-2"
                            />
                                <span className="ml-2 text-sm">{age.name}</span>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default FilterAgeBar;
