import React, { useEffect, useState } from 'react';
import CategoryNavBar from './CategoryNavBar';
import SideBar from './SideBar';

import UploadedImage from '../../../Assets/Customer/keren_70.jpg';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import axiosInstance from '../../../axiosInstance';
import { Helmet } from 'react-helmet';

const MyOrders = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>('PENDING');
  const navigate = useNavigate();
  const { companyId } = useParams();
  const [orders, setOrders] = useState<any[]>([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  //this is temporary
  // const CompleteOrder = {
  //   id: 5,
  //   orderNo: "ord-5",
  //   trackingId: 10,
  //   itemName: "testTwo",
  //   deliveryNote: "will be deilverd in the person",
  //   price: `LKR 2000`,
  //   deliveryCharge: `Delivery Charge: LKR 200`,
  //   total: `Total: LKR ${2000 + 200}`,
  //   qty: 6,
  //   brandName: "TropiClean",
  //   categoryName: "Grooming Products",
  //   status: "CONFIRMED",
  //   orderDate: "2024-08-16",
  //   imgUrl: UploadedImage,
  //   itemId: 11,
  // }


  const setStoreUser = (): { userId: string | null, token: string | null } => {
    const storeUser = sessionStorage.getItem("user");
    if (storeUser) {
      const user = JSON.parse(storeUser);
      const userId = user.id;
      const token = user.token;
      return { userId, token };
    }
    return { userId: null, token: null };
  };

  const { userId, token } = setStoreUser();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        if (userId) {
          const response = await axiosInstance.get(`/webOrder/getAllActiveOrders/${userId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200) {
            const fetchedOrders = response.data.data.orders.map((order: any) => ({
              id: order.id,
              orderNo: order.orderNo,
              trackingId: order.trackingId,
              orderDate: order.orderDate,
              deliveryNote: order.deliveryNote,
              amount: order.amount,
              deliveryCharge: order.deliveryCharge,
              total: order.amount + order.deliveryCharge,
              status: order.status,
              orderItems: order.orderItems.map((item: any) => ({
                itemName: item.itemName,
                brandName: item.brandName,
                categoryName: item.categoryName,
                imgUrl: item.imgUrl || UploadedImage,
                qty: item.qty,
                itemId: item.itemId,
                batchId: item.batchId,
              })),
            }));

            setOrders(fetchedOrders);
          } else {
            console.error("Failed to fetch orders");
          }
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, [userId, token, baseUrl]);

  const handleAddRoCart = async (itemId: string, batchId: string) => {
    if (token && userId) {
      const data = {
        id: null,
        quantity: 1,
        itemId: itemId,
        batchId: batchId,
        companyId: companyId
      };

      console.log("cart data :", data)
      try {
        const response = await axiosInstance.post(
          `/cart/cartItem/${userId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        console.log('Item added to cart:', response.data);

        // Display success alert
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Item added to cart successfully!',
          showConfirmButton: true,
        });
      } catch (error) {
        console.error('Error adding item to cart:', error);

        // Display error alert
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Failed to add item to cart!',
          showConfirmButton: true,
        });
      }

    }
  }

  const handleOnConform = async (trackId: number, orderId: number) => {
    const order = orders.find((order: any) => order.trackingId === trackId && order.id === orderId);
    console.log("trackingId: ", trackId);
    console.log("orderId: ", orderId);
    console.log("userId: ", userId);

    if (!order) {
      console.error('Order not found:', trackId, orderId);
      return;
    }

    try {
      console.log('Order found:', trackId, orderId);
      const response = await axiosInstance.post(
        `/webOrder/conformOrder/${userId}/${orderId}/${trackId}`,
        {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
      );
      if (response.status === 200) {
        console.log("response: ", response);
        setOrders(orders =>
          orders.map(o => (order.id === orderId ? { ...o, status: 'CONFIRMED' } : o))
        );
        console.log("order status: ", setOrders);
      } else {
        console.error('Failed to update order status:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while updating the order status:', error);
    }
  };

  const handleOnCancel = async (id: number) => {
    const order = orders.find((order: any) => order.id === id);
    console.log("orderId in cancel: ", id);

    if (order) {
      try {
        const response = await axiosInstance.post(`/webOrder/disable/${id}`,
          id, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        }
        );
        console.log('Order deleted successfully:', response.data);
        Swal.fire({
          title: 'Success',
          text: 'Order deleted successfully!',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });

      } catch (error) {
        console.error('Error submitting Order:', error);
        Swal.fire('Error', 'An error occurred. Please try again.', 'error');
      }
    }
  }


  useEffect(() => {
    const handleResize = () => {
      setSidebarVisible(window.innerWidth >= 770);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
    setSidebarVisible(!isSidebarVisible);
  };

  const handleTabClick = (filter: string) => {
    setSelectedFilter(filter);
  };

  const filteredOrders = orders.filter(order =>
    selectedFilter === 'CONFIRMED'
      ? order.status === 'CONFIRMED'
      : ['PENDING', 'IN_PROCESS', 'PROCESS_COURIER', 'HAND_IN_COURIER', 'DELIVERED'].includes(order.status)
  );

  //this is temporary
  // const filteredOrders = selectedFilter === 'CONFIRMED' 
  // ? [...orders.filter(order => order.status === selectedFilter), CompleteOrder] 
  // : orders.filter(order => order.status === selectedFilter);

  const navigateTracker = (orderId: number, itemId: number) => {
    navigate(`/shoppingCartItem/${companyId}/${userId}/${orderId}/${itemId}/tracking`);
  }

  const navigateReview = (orderId: number, itemId: number) => {
    navigate(`/shoppingCartItem/${companyId}/${userId}/${orderId}/${itemId}/review`);
  }




  const NextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        style={{
          ...style,
          right: '-35px',
          top: '50%',
          transform: 'translateY(-50%)',
          width: '50px',
          height: '50px',
          zIndex: 1,
          cursor: 'pointer',
          color: 'blue',
          fontSize: '30px'
        }}
        onClick={onClick}
      >
        <i className="bi bi-chevron-right"></i>
      </div>
    );
  };


  const PrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        style={{
          ...style,
          left: '-50px',
          top: '50%',
          transform: 'translateY(-50%)',
          width: '50px',
          height: '50px',
          zIndex: 1,
          cursor: 'pointer',
          color: 'blue',
          fontSize: '30px'
        }}
        onClick={onClick}
      >
        <i className="bi bi-chevron-left"></i>
      </div>
    );
  };


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };


  return (
    <>
      {/* <Helmet>
        <title>Pet Medi Web - My Orders Page</title>
        <meta name="description" content="Learn more about Pet Medi, your trusted pet health partner." />
        <meta
          name="keywords"
          content="pet, health, care, dogs, cats, veterinary,Pet clinic in Sri Lanka,Veterinary services Sri Lanka,Pet hospital,Affordable pet care Sri Lanka, 
      Veterinarian near me,Animal clinic Sri Lanka,Pet surgery ,Pet vaccinations Sri Lanka,24/7 emergency vet Sri Lanka, Pet health checkup Sri Lanka, Dog clinic Sri Lanka, 
      Cat veterinary care Sri Lanka, Exotic pet care Sri Lanka, Pet dental care Sri Lanka, Pet grooming, Pet boarding services Sri Lanka, Best vet for pet Sri Lanka, 
      Cataract surgery for pets Sri Lanka, Animal rescue services Sri Lanka ,Veterinary clinic , Sri Lanka , Pet Medi, pet, P , Sri Lanka Pets, Pet Care, Animal Clinic, 
      Vet, Pet Health, Pet Health Checkup, veterinary,Pet dental care, Grooming, Best Animal Clinic, Pet health monitoring app, Veterinary telemedicine Sri Lanka,Pet prescription management,
      Pet weight management Sri Lanka,Pet nutrition advice Sri Lanka,Digital veterinary solutions,Pet clinic appointment system,Senior pet care services,Specialized pet care Sri Lanka,
      Online vet consultation Sri Lanka,Pet illness prevention,Remote vet consultations,Pet healthcare software,Preventive vet care Sri Lanka,Pet emergency hotline Sri Lanka,
      Advanced pet diagnostics,Pet care plans Sri Lanka,In-home veterinary services,Pet surgery aftercare Sri Lanka,Pet grooming and spa Sri Lanka,Veterinary lab testing Sri Lanka,
      Personalized pet healthcare plans,Vet treatment packages Sri Lanka,Animal health solutions Sri Lanka,Vet treatment plans Sri Lanka,Pet physiotherapy Sri Lanka, 
      Vets near me, cat clinic near me, dog clinic near me, rabbit consultant doctors, dog consultant doctors, cat consultant doctors, online pet app, pet app online, 
      app for pets, app for animals, animal doctor, pet doctor, pet consultant, Pet wellness programs Sri Lanka, Pet wellness programs, Veterinary nutrition consultation, 
      Pet behavior training Sri Lanka, Pet behavior training, Veterinary dermatology Sri Lanka, Veterinary dermatology, Puppy vaccinations, Puppy vaccinations Sri Lanka, 
      Kitten vaccinations Sri Lanka, Kitten vaccinations, Flea and tick prevention Sri Lanka, Flea and tick prevention, Microchipping services Sri Lanka, Pet allergy treatment Sri Lanka, Pet allergy treatment,
      Veterinary orthopedic surgery Sri Lanka,Veterinary orthopedic surgery, 24-hour vet service Sri Lanka, 24-hour vet service , Emergency pet care Sri Lanka, Emergency pet care,
      Animal eye care Sri Lanka,Animal eye care,Pet dental cleaning Sri Lanka,Pet dental cleaning,Dog vaccinations Sri Lanka, Dog vaccinations,Cat vaccinations Sri Lanka,Cat vaccinations,Animal diagnostic imaging,
      Vet appointment booking online Sri Lanka,Vet appointment booking online,Pet Surgery Sri Lanka, Pet Surgery,Specialist vet services Sri Lanka,Specialist vet services,Pet cardiologist Sri Lanka, Pet cardiologist,
      Best dog vet Sri Lanka,Best dog vet, Best cat vet Sri Lanka, Best cat vet,Pet health checkup Sri Lanka, Pet health checkup, Puppy health checkup Sri Lanka,Puppy health checkup, Kitten health checkup Sri Lanka,Kitten health checkup,
      Emergency pet clinic near me,Pet health and wellness Sri Lanka,Pet health and wellness , pet treatment,Pet Health, Pet Services,Pet Services near me,Pet health diagnosis,Veterinary diagnosis,Common pet diseases,Animal health conditions,
      Dog illness diagnosis,Cat health problem, Dog health problem, Pet health problem, Pet disease symptoms, Veterinary medical conditions, Signs of illness in pets, Pet diagnostic tests,
      Dog allergy diagnosis, Cat urinary tract infection symptoms, Dog urinary tract infection symptoms, Pet urinary tract infection symptoms, Veterinary blood tests for pets, Pet arthritis treatment,
      Dog diabetes diagnosis, Cat diabetes diagnosis, Pet diabetes diagnosis, Pet cancer signs, Common pet skin diseases, Animal ear infections, Dog digestive problems diagnosis,
      Cat digestive problems diagnosis, Pet digestive problems diagnosis, Pet liver disease symptoms, Kidney disease in cats, Kidney disease in dogs, Kidney disease in pets,
      Pet obesity treatment, Dog heart disease symptoms, Cat heart disease symptoms, Pet heart disease symptoms, Cat thyroid disease, Dog thyroid disease, Pet thyroid disease,
      Pet parasite infections, Veterinary checkup for pets, Dog hip dysplasia diagnosis, Cat respiratory infections, Dog respiratory infections, Pet respiratory infections, Pancreatitis in pet symptoms, Dog Food, Cat Food"
    />
      </Helmet> */}

      <div>
        <CategoryNavBar isDropdownOpen={isDropdownOpen} handleDropdownToggle={handleDropdownToggle} />
        <div className="px-6 sm:px-12 lg:px-16 bg-gray-100 pb-5">
          <div className="flex flex-col sm:flex-row gap-4 md:gap-8">
            {/* {isSidebarVisible && <SideBar height="420px" />} */}

            <div className="w-full mt-6">
              {/* <div className="flex flex-col md:flex-row gap-4 px-6 sm:px-12 md:px-1 lg:px-1 bg-gray-100 pb-4 filter items-center justify-end">
          <div className="bg-white p-4 rounded-md shadow-md w-full md:w-52 text-center md:text-left">
              <h2 className="text-lg font-semibold mb-4">Deliveries</h2>
              <div className="flex items-center justify-center md:justify-start">
              <div className="tabs w-full">
                  <button
                    className={`tab ${selectedFilter === 'Await' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} px-4 py-2 rounded-l-md`}
                    onClick={() => handleFilterChange('Await')}
                    >
                    Awaiting Delivery
                    </button>
                    <button
                    className={`tab ${selectedFilter === 'Completed' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} px-4 py-2 rounded-r-md`}
                    onClick={() => handleFilterChange('Completed')}>
                      Completed Delivery
                    </button>
                </div>
            </div>
          </div>
        </div> */}
              <div className="flex justify-center mb-4 flex-wrap">
                <button
                  className={`'px-2 py-1 sm:px-4 sm:py-2 mr-2 sm:mr-4 mb-2 sm:mb-0 ${['PENDING', 'IN_PROCESS', 'PROCESS_COURIER', 'HAND_IN_COURIER', 'DELIVERED'].includes(selectedFilter)
                      ? 'text-blue-500 border-b-2 border-blue-500'
                      : 'text-gray-500'
                    }`}
                  onClick={() => handleTabClick('PENDING')}
                >
                  Awaiting Delivery
                </button>
                {/* <button
          className={`px-2 py-1 sm:px-4 sm:py-2 mr-2 sm:mr-4 mb-2 sm:mb-0 ${selectedFilter === 'IN_PROCESS' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'}`}
          onClick={() => handleTabClick('IN_PROCESS')}
        >
          IN_PROCESS Delivery
        </button> */}
                <button
                  className={`px-2 py-1 sm:px-4 sm:py-2 ${selectedFilter === 'CONFIRMED' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'}`}
                  onClick={() => handleTabClick('CONFIRMED')}
                >
                  Completed Delivery
                </button>
              </div>



              <div className="cards w-full mt-6 items-center justify-center">
                {filteredOrders.map(order => (
                  <div key={order.id} className="bg-white p-4 rounded-md shadow-md mb-4 flex flex-col items-center md:items-start">
                    <div className="flex flex-col md:flex-row justify-between items-center md:items-center mb-2 w-full">
                      <ul>
                        {order.orderItems.map((item: any) => (
                          <li key={item.itemId} className="order-item">
                            <div className="flex flex-col md:flex-row items-center md:items-center">
                              <img
                                src={item.imgUrl}
                                alt="Product"
                                className="w-20 h-20 sm:w-16 sm:h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-md mr-0 md:mr-4 mb-2 md:mb-0"
                              />
                              <div className="text-center md:text-left mt-4">
                                <p className="text-sm sm:text-base font-medium text-gray-700">{item.itemName}</p>
                                <p className="text-xs sm:text-sm text-gray-500">{item.brandName}</p>
                                <p className="text-xs sm:text-sm text-gray-500">{item.categoryName}</p>
                                <p className="text-xs sm:text-sm text-gray-500">{item.description}</p>
                                <p className="text-xs sm:text-sm text-gray-500">{item.price}</p>
                                <p className="text-xs sm:text-sm text-gray-500">{item.deliveryCharge}</p>
                                <p className="text-xs sm:text-sm text-gray-500">Qty: {item.qty}</p>
                              </div>
                            </div>
                          </li>

                        ))}
                      </ul>
                      <div className="flex flex-col items-center md:items-end mb-4 space-y-2">
                        <p className="text-xs sm:text-sm font-medium text-gray-700">Total Price : Rs {order.total}</p>
                        {order.status === 'CONFIRMED' ? (
                          <div className="w-full flex flex-col mt-2 md:space-x-2 space-y-2">
                            <button className="w-full  px-4 py-2 right-0 bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-sm font-medium rounded-md text-white" onClick={() => navigateReview(order.id, order.orderItems[0]?.itemId)}>
                              Write a Review
                            </button>
                            <button className="w-full  px-4 py-2 border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white text-sm font-medium rounded-md" onClick={() => handleAddRoCart(order.itemId, order.batchId)}>
                              Add to Cart
                            </button>
                            <button className="w-full  px-4 py-2 border border-red-600 text-red-600 hover:bg-red-600 hover:text-white text-sm font-medium rounded-md" onClick={() => handleOnCancel(order.id)}>
                              Remove
                            </button>
                          </div>
                        ) : (
                          <>
                            <button className="w-full px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-sm font-medium rounded-md text-white" onClick={() => handleOnConform(order.trackingId, order.id)}>
                              Confirm received
                            </button>
                            <button className="w-full px-4 py-2 border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white text-sm font-medium rounded-md" onClick={() => navigateTracker(order.id, order.orderItems[0]?.itemId)}>
                              Track order
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="text-center md:text-right mt-2 md:mt-0 w-full">
                      <p className="text-xs sm:text-sm text-gray-500">Order date: {order.orderDate}</p>
                      <p className="text-xs sm:text-sm text-gray-500">Order ID: {order.orderNo}</p>
                      {/* <p className="text-xs sm:text-sm font-medium text-gray-700 mt-2">Order details</p> */}
                    </div>
                  </div>
                ))}
                {filteredOrders.length === 0 && (
                  <p className="text-center text-sm sm:text-base text-gray-500">
                    No orders found.
                  </p>
                )}
              </div>

            </div>


          </div>
        </div>


      </div>

    </>
  );
};

export default MyOrders;
