import React, { useEffect, useState } from 'react';
import Input from '../../component/input/input';
import ComboBox from '../../component/input/combobox';
import * as Validator from "../../../util/validator";
import { cityData } from '../../../util/cityData';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

type AddNewAddressFormProps = {
  isnewAddress: boolean;
  isChangeAddress: boolean;
  isEditAddress: boolean;
  closeModal: Function
};

const AddNewAddressForm: React.FC<AddNewAddressFormProps> = ({ isnewAddress, isChangeAddress, isEditAddress, closeModal }) => {
  
  //get auth and token
  const setStoreUser = (): { userId: string | null, auth: string | null } => {
    const storeUser = sessionStorage.getItem("user");
    
    if (storeUser) {
      const user = JSON.parse(storeUser);
      const userId = user.id;
      const auth = user.token;

      return { userId, auth };
    }    
    return { userId: null, auth: null };
  };

  const { userId, auth } = setStoreUser();

  //form data
  const [formData, setFormData] = useState({
    id: null,
    fullName: '',
    address: '',
    defaultAddress: false,
    mobileNumber: '',
    district: '',
    city: '',
    webUserId: userId
  });

  //valid form
  const [formValidity, setFormValidity] = useState({
    isValidName: true,
    isValidAddress: true,
    isValidMobile: true,
    isValidDistrict: true,
    isValidCity: true,
  });

  const [addressData, setDeliveryAddress] = useState([]);
  const [isClickChangeAddress, setChangeAddress] = useState<boolean>(false);
  const [isClickNewAddress, setNewAddress] = useState<boolean>(false);
  const [isClickEditAddress, setEditAddress] = useState<boolean>(false);
  const [cities, setCities] = useState([{ value: '', label: 'Please select your district first' }]);

  // Get customer delivery address 
  useEffect(() => {
    axios.get(`${baseUrl}/delivery_detail/getByUser/${userId}`, {
      headers: {
        Authorization: `Bearer ${auth}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.status === 200 && response.data.data) {
          setDeliveryAddress(response.data.data.deliveryDetails);
        } else {
          console.error('Unexpected response status:', response.status);
        }
      })
      .catch(error => {
        console.error('Error fetching address data:', error);
      });
  }, [userId, auth]);

  //for popup address dialog
  useEffect(() => {
    setNewAddress(isnewAddress);
    setChangeAddress(isChangeAddress);
    setEditAddress(isEditAddress);
  }, [isnewAddress, isChangeAddress, isEditAddress]);

  //set districts
  const districts = cityData().map(district => ({ value: district.name, label: district.name }));

  //set city by district selected
  useEffect(() => {
    if (formData.district) {
      const selectedDistrict = cityData().find(district => district.name === formData.district);
      if (selectedDistrict) {
        const updatedCities = selectedDistrict.cities.map(city => ({ value: city, label: city }));
        setCities(updatedCities);
      }
    }
  }, [formData.district]);

  //handle input data
  const handleInput = (e: any, name: string) => {
    let value = e.target.value;  
    setFormData(prevData => ({ ...prevData, [name]: value }));
  
    if (name === 'district') {
      const selectedDistrict = cityData().find(district => district.name === value);
      if (selectedDistrict) {
        const updatedCities = selectedDistrict.cities.map(city => ({ value: city, label: city }));
        setCities(updatedCities);
        setFormData(prevData => ({ ...prevData, city: '' }));
      } else {
        setCities([{ value: '', label: 'Please select your district first' }]);
      }
  
      setFormValidity(prevValidity => ({
        ...prevValidity,
        isValidDistrict: value.trim() !== '',
        isValidCity: false,
      }));
  
    } else if (name === 'city') {
      setFormValidity(prevValidity => ({
        ...prevValidity,
        isValidCity: value.trim() !== '',
      }));
  
    } else {
      switch (name) {
        case "fullName":
          setFormValidity(prevValidity => ({
            ...prevValidity,
            isValidName: Validator.validateName(value),
          }));
          break;
        case "address":
          setFormValidity(prevValidity => ({
            ...prevValidity,
            isValidAddress: Validator.validateAddress(value),
          }));
          break;
        case "mobileNumber":
          setFormValidity(prevValidity => ({
            ...prevValidity,
            isValidMobile: Validator.validateMobile(value),
          }));
          break;
      }
    }
  };
  
  const validateForm = () => {
    const isValidName = Validator.validateName(formData.fullName);
    const isValidAddress = Validator.validateAddress(formData.address);
    const isValidMobile = Validator.validateMobile(formData.mobileNumber);
    const isValidDistrict = formData.district.trim() !== '';
    const isValidCity = formData.city.trim() !== '';

    setFormValidity({
      isValidName,
      isValidAddress,
      isValidMobile,
      isValidDistrict,
      isValidCity,
    });

    return isValidName && isValidAddress && isValidMobile && isValidDistrict && isValidCity;
  };

  // Save Form data
  const handleSubmit = () => {

    //validate address
    if (validateForm()) {
      const modifiedFormData = {
        ...formData,
        address: formData.address.replace(/, ?/g, ', '),
        defaultAddress: addressData === undefined ? true : formData.defaultAddress,
      };
  
      axios.post(`${baseUrl}/delivery_detail`, 
        modifiedFormData,
        {
          headers: {
            Authorization: `Bearer ${auth}`,
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          if (response.status === 200 && response.data.data) {
            console.log("Address saved!");
            window.location.reload();
            closeModal();
          } else {
            console.error('Unexpected response status:', response.status);
          }
        })
        .catch(error => {
          console.error('Error saving address:', error);
        });
    }
  };
  
  //set delivery address 
  const handleCheckboxChange = (event: any) => {
    if (formData.defaultAddress !== true) {
      setFormData({
        ...formData,
        defaultAddress: event.target.checked,
      }); 
    }
  };

  // for edit address data edit
  const handleAddressData = (address: any) => {
    if (address.district) {
      const selectedDistrict = cityData().find(district => district.name === address.district);
      if (selectedDistrict) {
        const updatedCities = selectedDistrict.cities.map(city => ({ value: city, label: city }));
        setCities(updatedCities);
      }
    } else {
      setCities([{ value: '', label: 'Please select your district first' }]);
    }

    setFormData({
      id: address.id,
      fullName: address.fullName,
      address: address.address,
      defaultAddress: address.defaultAddress,
      mobileNumber: address.mobileNumber,
      district: address.district,
      city: address.city,
      webUserId: address.webUserId
    });
  };
  
  return (
    <div className="bg-white rounded-lg ">
      {isClickNewAddress || isClickEditAddress?(
        <div>
            <div className="p-5">
              <h2 className="md:text-base text-sm">{isClickEditAddress ? "Edit Delivery Address" : "Add New Delivery Address"}</h2>
            </div>
            <div className="bg-gray-50 p-5 rounded-lg max-h-[450px] md:max-h-[600px] md:overflow-none overflow-auto">
              {/* <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-6 md:text-sm text-xs"> */}
              <form  className="grid grid-cols-1 md:grid-cols-2 gap-6 md:text-sm text-xs">
              
              {/* for full name */}
                <Input
                  type="text"
                  name="fullName"
                  label="Full Name"
                  optional={true}
                  value={formData.fullName}
                  callBack={handleInput}
                  placeholder="Full name"
                  isValid={formValidity.isValidName}
                  errorMessage="invalid name!"
                />

                {/*for address  */}
                <Input
                  type="text"
                  name="address"
                  label="Address"
                  optional={true}
                  value={formData.address}
                  callBack={handleInput}
                  placeholder="House no. / building / street / area"
                  isValid={formValidity.isValidAddress}
                  errorMessage="invalid address!"
                />     
                
                {/* for mobile */}
                <Input
                  type="text"
                  name="mobileNumber"
                  label="Mobile Number"
                  optional={true}
                  value={formData.mobileNumber}
                  callBack={handleInput}
                  placeholder="Input mobile number"
                  isValid={formValidity.isValidMobile}
                  errorMessage="invalid mobile!"
                />

                {/* for district */}
                <ComboBox
                  name="district"
                  label="District"
                  value={formData.district}
                  options={districts}
                  onChange={(e) => handleInput(e, 'district')}
                  isValid={formValidity.isValidDistrict}
                  errorMessage="Please select a district!"
                />

                {/* for city */}
                <ComboBox
                  name="city"
                  label="City"
                  value={formData.city}
                  options={cities}
                  onChange={(e) => handleInput(e, 'city')}
                  isValid={formValidity.isValidCity}
                  errorMessage="Please select a city!"
                />
                <div></div>

                {/* for order receive location check box */}
               <label>
                  <input
                    type="checkbox"
                    checked={formData.defaultAddress}
                    onChange={handleCheckboxChange}
                    className='m-1'
                    // disabled={formData.defaultAddress}
                  />
                 Set as receive order location.
                </label>

                {/* save or update button */}
                <div className="md:col-span-2 mt-4">
                  <button type='button' onClick={handleSubmit} disabled={!Object.values(formValidity).every(Boolean)} className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 disabled:bg-gray-400">{isClickEditAddress ? "Update" : "Save"}</button>
                </div>
              </form>
            </div>
      </div>
      ): isClickChangeAddress ? (
        <div>
          <div className="p-5">
              <h2 className="md:text-base text-sm">Shopping Address</h2>
          </div>
          <div className="bg-gray-50 p-5 rounded-lg max-h-[450px] md:max-h-[490px] md:overflow-none overflow-auto">
              {addressData.map((address:any, index:any) =>(
                <div key={index} className="p-4 rounded-lg mb-4 flex justify-between bg-white border-2 border-blue-200">
                <div className="text-left">
                  <p className="text-sm font-semibold">
                    {address.fullName}
                  </p>
                  <p className="text-sm">{address.mobileNumber}</p>
                  <p className="text-sm">{address.address}, {address.city} ,</p>
                  <p className="text-sm">{address.district} .</p>
                </div>
                <div className="text-right ml-auto">
                  <button className="text-blue-500 text-sm" onClick={() => {
                  setNewAddress(false);
                  setChangeAddress(false);
                  setEditAddress(true);
                  handleAddressData(address);
                }}>Edit</button>
                </div>
              </div>
              ))}
              <div className="flex justify-center items-center">
                <button className="bg-blue-500 text-white py-2 px-4 rounded" 
                onClick={() => {
                  setNewAddress(true);
                  setChangeAddress(false);
                }}>Add New Address</button>
              </div>
          </div>
        </div>
      ) : (
        <div></div>
      )
    
    }
      
    </div>
  );
};

export default AddNewAddressForm;
