import React, { ChangeEvent, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';
import Swal from 'sweetalert2';

const baseUrl = process.env.REACT_APP_BASE_URL;

const SignUpPage: React.FC = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [contact, setContact] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loginModel, setLoginModal] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    if (id === 'firstName') setFirstName(value);
    if (id === 'lastName') setLastName(value);
    if (id === 'address') setAddress(value);
    if (id === 'email') setEmail(value);
    if (id === 'password') setPassword(value);
    if (id === 'confirmPassword') setConfirmPassword(value);
  };

  const handlePhoneChange = (value: string) => {
    setContact(value);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleLogin = () => {
    setLoginModal(!loginModel);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate password confirmation
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    // Construct the request payload
    const payload = {
      id: null,
      firstName,
      lastName,
      password,
      email,
      mobileNumber: contact,
      address,
      tryCount: 0,
      active: true,
      locked: true
    };

    try {
      const response = await axios.post(`${baseUrl}/webUser/save`, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      // Handle success response
      // setSuccessMessage('Registration successful!');
      setErrorMessage(''); // Clear error message if successful
      Swal.fire('Success', 'Registration successful!', 'success');
      console.log('Response:', response.data);
      if(!loginModel == true){
        toggleLogin();
      }
      else{
        Swal.fire('Error', 'Registration failed. Please try again.');
      }
    } catch (error) {
      // Handle error response
      setErrorMessage('Registration failed. Please try again.');
      setSuccessMessage(''); // Clear success message if failed
      Swal.fire('Error', 'An error occurred. Please try again.', 'error');
      console.error('Error:', error);
    }
  };

  return (
    <form className="space-y-6 pb-6" onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col space-y-6">
          <div className="form-floating mb-3">
            <input
              type="text"
              id="firstName"
              placeholder='First Name'
              value={firstName}
              onChange={handleChange}
              className="form-control mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
              style={{ height: '5vh', width: '100%' }}
            />
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              id="address"
              placeholder='Address'
              value={address}
              onChange={handleChange}
              className="form-control mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
              style={{ height: '5vh', width: '100%' }}
            />
          </div>
          <div className="form-floating mb-3">
            <input
              type="email"
              id="email"
              placeholder='Email'
              value={email}
              onChange={handleChange}
              className="form-control mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
              style={{ height: '5vh', width: '100%' }}
            />
          </div>
          <div className="form-floating mb-3 relative">
            <input
              type={passwordVisible ? 'text' : 'password'}
              id="confirmPassword"
              placeholder='Confirm Password'
              value={confirmPassword}
              onChange={handleChange}
              className="form-control mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
              style={{ height: '5vh', width: '100%' }}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-3 text-gray-500 hover:text-gray-700"
            >
              <i className={`bi ${passwordVisible ? 'bi-eye-slash' : 'bi-eye'}`}></i>
            </button>
          </div>
          
        </div>
        <div className="flex flex-col space-y-6">
          <div className="form-floating mb-3">
            <input
              type="text"
              id="lastName"
              placeholder='Last Name'
              value={lastName}
              onChange={handleChange}
              className="form-control mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
              style={{ height: '5vh', width: '100%' }}
            />
          </div>
          <div className="form-floating mb-3">
            <PhoneInput
              country={'lk'}
              value={contact}
              placeholder="Mobile No"
              onChange={handlePhoneChange}
              inputProps={{
                name: 'contact',
                required: true,
              }}
              containerStyle={{
                width: '100%',
              }}
              inputClass="block w-full py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              inputStyle={{
                height: '5vh',
                width: '100%',
                paddingLeft: '3rem',
                paddingRight: '1rem',
                borderRadius: '0.375rem',
                fontSize: '0.875rem',
              }}
              buttonStyle={{
                height: '5vh',
                background: 'white'
              }}
            />
          </div>
          <div className="form-floating mb-3 relative">
            <input
              type={passwordVisible ? 'text' : 'password'}
              id="password"
              placeholder='Password'
              value={password}
              onChange={handleChange}
              className="form-control mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
              style={{ height: '5vh', width: '100%' }}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-3 text-gray-500 hover:text-gray-700"
            >
              <i className={`bi ${passwordVisible ? 'bi-eye-slash' : 'bi-eye'}`}></i>
            </button>
          </div>

        </div>
      </div>
      {errorMessage && (
        <div className="flex items-center text-red-600 mb-4">
          <svg className="w-8 h-8 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
          <p>{errorMessage}</p>
        </div>
      )}
      {successMessage && (
        <div className="flex items-center text-green-600 mb-4">
          <svg className="w-8 h-8 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
          <p>{successMessage}</p>
        </div>
      )}
      <div className="flex items-center justify-center lg:pb-8">
        <button
          type="submit"
          className="w-full items-center justify-center inline-flex px-7 py-[0.7rem] border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Register
        </button>
      </div>
    </form>
  );
};

export default SignUpPage;
