import React, { Component } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import Home from '../../pages/Home/Home'
import About from '../../pages/About/About'
// import Pricing from '../../pages/Pricing/Pricing'
import Contact from '../../pages/Contact/Contact'
import QuickGuide from '../../pages/QuickGuide/QuickGuide'
import Customer from '../../pages/Customer/Customer'
import Items from '../../pages/Items/Items'
import ShoppingCartHome from '../../pages/Shopping-cart/ShoppingCartHome'
import ShoppingCartItem from '../../pages/Shopping-cart/ShoppingCartItem'
import PrivacyPolicy from '../../pages/Privacy Policy/PrivacyPolicy'
import CheckoutPage from '../../pages/Shopping-cart/CheckoutPage'
import Terms from '../../pages/Terms/Terms '
import CartView from '../../pages/Shopping-cart/CartView'
import LoginModal from '../../pages/Items/LoginModal'
import Wishlist from '../../pages/Shopping-cart/WishlistItem'
import WishlistItem from '../../pages/Shopping-cart/WishlistItem'
import Pricing from '../../pages/Pricing/Pricing'
import Navbar from '../Navbar/Navbar'
import MyOrders from '../../pages/Shopping-cart/MyOrders'
import ReviewForm from '../../pages/Shopping-cart/ReviewForm'
import Tracking from '../../pages/Shopping-cart/Tracking'
import Blog from '../../pages/Blog/Blog'
import BlogView from '../../pages/Blog/BlogView'

export default class Main extends Component {

  shouldHideNavbar() {
    const hideNavbarRoutes = [
      '/shoppingCartItem/item/:companyId/:itemId/:batchId',
      '/shoppingCartItem/',
      '/shoppingCartItem/',
      '/shoppingCartItem/',
      '/shoppingCartItem/',
      '/shoppingCartItem/checkout',
      '/shoppingCartItem/wishlist'
    ];

    return hideNavbarRoutes.some(route => window.location.pathname.startsWith(route));
  }

  render() {

    const shouldHideNavbar = this.shouldHideNavbar();

    return (
      <div className='mx-auto'>
        <Navbar hideNavbar={shouldHideNavbar} />

        <Routes>
          <Route path='/*' Component={Home}></Route>
          <Route path='/about' Component={About}></Route>
          <Route path='/pricing' Component={Pricing}></Route>
          <Route path='/pricing/:id' Component={Pricing}></Route>
          <Route path='/contact' Component={Contact}></Route>
          <Route path='/quickGuide' Component={QuickGuide}></Route>
          <Route path='/customer' Component={Customer}></Route>
          <Route path='/shoppingCartItem/item/:companyId/:itemId/:batchId' Component={Items}></Route>
          <Route path='/shoppingCartItem/:companyId' element={<ShoppingCartHome />}></Route>
          <Route path='/shoppingCartItem/:companyId/:categoryId/:brandId?' element={<ShoppingCartItem />}></Route>
          <Route path='/shoppingCartItem/brand/:companyId/:brandId' element={<ShoppingCartItem />}></Route>
          <Route path='/shoppingCartItem/search/:companyId' element={<ShoppingCartItem />}></Route>
          <Route path='/shoppingCartItem/:companyId/cart' element={<CartView />}></Route>
          <Route path='/policy' Component={PrivacyPolicy}></Route>
          <Route path='/shoppingCartItem/:companyId/:branchId/checkout' Component={CheckoutPage}></Route>
          <Route path='/terms' Component={Terms}></Route>
          <Route path='/shoppingCartItem/:companyId/wishlist' Component={WishlistItem}></Route>
          <Route path='/shoppingCartItem/:companyId/:userId/myOrders' Component={MyOrders}></Route>
          <Route path='/shoppingCartItem/:companyId/:userId/:orderId/:itemId/review' Component={ReviewForm}></Route>
          <Route path='/shoppingCartItem/:companyId/:userId/:orderId/:itemId/tracking' Component={Tracking}></Route>
          <Route path='/blog' Component={Blog}></Route>
          <Route path='/blogView/:postId' Component={BlogView}></Route>
        </Routes>
      </div>
    )
  }
}
