import React from "react";
// @ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const items = [
  {
    id: 1,
    img: 'https://img.freepik.com/free-psd/black-friday-super-sale-facebook-cover-template_106176-1588.jpg?t=st=1720944542~exp=1720948142~hmac=b87dc0a1aaf9f5e4892eb0999308f897e3e36457c4f9d01add053e11147b2533&w=740',
    title: 'Sample Image 1'
  },
  {
    id: 2,
    img: 'https://img.freepik.com/free-psd/digital-marketing-agency-corporate-facebook-cover-template_106176-2258.jpg?ga=GA1.1.1473019030.1714676778&semt=sph',
    title: 'Sample Image 2'
  },
  {
    id: 3,
    img: 'https://img.freepik.com/free-vector/gradient-business-horizontal-banner_23-2149695724.jpg?ga=GA1.1.1473019030.1714676778&semt=sph',
    title: 'Sample Image 3'
  }
];

const BannerSection = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className="flex-1 pt-6 overflow-hidden">
      <Slider {...settings}>
        {items.map((item) => (
          <div key={item.id} className="text-center overflow-hidden">
            <img
              src={item.img}
              alt={item.title}
              loading="lazy"
              className="w-full h-full rounded-lg"
              //className="w-full h-full rounded-lg object-contain bg-center"
              style={{ maxHeight: "420px", maxWidth: "100%" }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BannerSection;
