import React, { useEffect, useState } from "react";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";

const clientId =
  "1023979584830-f19944uv2q8j0u6fegft41bm4si8fhci.apps.googleusercontent.com";

interface LoginProps {
  onSuccess: (response: any) => void;
  onFailure: (error: any) => void;
}

interface User {
  id: string;
  name: string;
  token: string;
  role: string;
  companyId: string | null;
  needMetaData: string | null;
  profilePicture: string;
}

const Login: React.FC<LoginProps> = ({ onSuccess, onFailure }) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleSuccess = (res: any) => {
    console.log("Login Success! Current User: ", res.profileObj);
    if (user != null) {
      onSuccess(res);
    } else {
      setUser(res.profileObj);
    }
  };

  const handleFailure = (res: any) => {
    console.log("Login Failure! res: ", res);
    onFailure(res);
  };

  return (
    <div id="signInButton">
      <GoogleLogin
        clientId={clientId}
        buttonText="Log in with Google"
        onSuccess={handleSuccess}
        onFailure={handleFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={true}
        redirectUri="https://petmedi.lk/auth/callback"
        // accessType={accessToken}
        className="w-full text-center text-2xl"
      />
    </div>
  );
};

export default Login;
