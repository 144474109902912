import React, { useEffect, useState } from 'react';

import SideBar from "./SideBar";
import BannerSection from "./BannerSection";
import HotItems from "./HotItems";
import BrandsSlider from './BrandSlider';
import CategoryNavBar from "./CategoryNavBar";
const baseUrl = process.env.REACT_APP_BASE_URL;

export default function ShoppingCartHome() {
    const [isDropdownOpen, setDropdownOpen] = useState(true);
    const [isSidebarVisible, setSidebarVisible] = useState(true);
    const handleResize = () => {
        setSidebarVisible(window.innerWidth >= 770);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleDropdownToggle = () => {
        setDropdownOpen(!isDropdownOpen);
        setSidebarVisible(!isSidebarVisible);
    };
    const handleCartClick = () => {
        console.log("hiii")
    };

    return (
        <div>
            <CategoryNavBar isDropdownOpen={isDropdownOpen} handleDropdownToggle={handleDropdownToggle} handleCartClick={handleCartClick} />
            <div className="px-6 sm:px-12 lg:px-16 bg-gray-100">
                <div className="flex flex-col sm:flex-row gap-4 md:gap-8">
                    {isSidebarVisible && <SideBar height="420px" />}
                    <BannerSection />
                </div>
                <HotItems />
                <BrandsSlider />
            </div>
        </div>
    );
}
