import React, { useState } from "react";

const petActivityType = [
    { name: 'Outdoor' },
    { name: 'Indoor'},
];


const FilterActivityType = () => {


    // State to manage which category's checkbox is active
    const [activeCategories, setActiveCategories] = useState<{ [key: number]: boolean }>({});

    const handleCheckboxChange = (index: number) => {
        setActiveCategories((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    return (
        <div className="pt-6">
            <div className="overflow-hidden rounded-2xl shadow-md w-full md:w-52">
                <ul className="bg-white p-2 w-full overflow-y-auto scrollbar-thin scrollbar-thumb-blue-700 scrollbar-track-white">
                    {petActivityType.map((type, index) => (
                        <li key={index} className="py-1 flex items-center">
                            <button
                                type="button"
                                className="flex items-center p-2 hover:bg-blue-100 rounded-md w-full text-left"
                                onClick={() => handleCheckboxChange(index)}
                            >
                            <input
                                type="checkbox"
                                checked={!!activeCategories[index]}
                                onChange={() => handleCheckboxChange(index)}
                                className="mr-2"
                            />
                                <span className="ml-2 text-sm">{type.name}</span>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default FilterActivityType;
