import React, { useState } from 'react';

interface ShippingMethod {
  id: number;
  deliveryMode: string;
  description: string;
}

interface ShippingSectionProps {
  shippingMethods: ShippingMethod[];
  onShippingMethodSelect: (method: ShippingMethod) => void;
}

const ShippingSection: React.FC<ShippingSectionProps> = ({
  shippingMethods,
  onShippingMethodSelect,
}) => {
  const [selectedShippingMethod, setSelectedShippingMethod] = useState<number | null>(null);

  const handleShippingMethodChange = (method: ShippingMethod) => {
    setSelectedShippingMethod(method.id);
    onShippingMethodSelect(method);
  };

  return (
    <div className="bg-white shadow rounded-md p-4 mb-4">
      <h2 className="text-sm font-semibold mb-4">Select a Shipping Method</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {shippingMethods.map((method) => (
          <div
            key={method.id}
            className={`relative p-4 rounded-lg border transition-shadow duration-300 ease-in-out cursor-pointer
              ${selectedShippingMethod === method.id
                ? 'border-blue-500 shadow-lg'
                : 'border-gray-300 hover:shadow-md hover:border-blue-300'
              }`}
            onClick={() => handleShippingMethodChange(method)}
          >
            {selectedShippingMethod === method.id && (
              <span className="material-icons absolute top-2 right-2 bg-blue-500 text-white rounded-full w-4 h-4 flex items-center justify-center">task_alt</span>
            )}
            <label className="flex flex-col items-start">
              <input
                type="radio"
                value={method.id}
                checked={selectedShippingMethod === method.id}
                onChange={() => handleShippingMethodChange(method)}
                className="mr-2 hidden"
              />
              <div className="w-full cursor-pointer">
                <p className="font-semibold text-xs">{method.deliveryMode}</p>
                <p className="text-xs text-gray-600 mt-1">{method.description}</p>
                {/* //<p className="text-xs font-medium mt-2">Rs. {method.price.toFixed(2)}</p> */}
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShippingSection;
