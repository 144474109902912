import React, { useState } from 'react';

interface ForgotOtpFormProps {
    onVerify: (otp: string) => void;
    onClose: () => void;
    message: string | null;
}

const ForgotOtpForm: React.FC<ForgotOtpFormProps> = ({ onVerify, onClose, message }) => {
    const [otp, setOtp] = useState<string>('');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onVerify(otp);
    };

    return (
        <div>
            <h2 className="text-2xl font-bold">Verify OTP</h2>
            <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="otp" className="block text-sm font-medium text-gray-700">OTP</label>
                    <input
                        type="text"
                        id="otp"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-blue-600 transition duration-300 ease-in-out"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                    />
                </div>

                {message && <div className="text-green-500 text-sm">{message}</div>}
                <button type="submit" className="w-full bg-blue-600 text-white py-2 px-4 mt-5 rounded hover:bg-blue-700 transition duration-300 ease-in-out">
                    Verify OTP
                </button>
                <button onClick={onClose} className="text-blue-600 hover:underline mt-2">
                    Back to Login
                </button>
            </form>
        </div>
    );
};

export default ForgotOtpForm;
