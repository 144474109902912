import React from "react";

interface Props {
    type: string;
    name: string;
    placeholder?: string;
    label: string;
    optional: boolean;
    value?: string;
    callBack:Function;
    errorMessage: string;
    isValid: boolean;
}

const Input = ({ type, name, placeholder, label, optional, value, callBack, errorMessage, isValid}: Props) => {
    return (
        <div className="mb-0 w-full mx-1">
            <label htmlFor={name} className="block text-black text-[15px] font-[500] mb-0.5">
                {label}{!optional ? <span className="text-red-600"> *</span> : null}
            </label>
            <input
                placeholder={placeholder}
                type={type}
                id={name}
                value={value}
                onChange={(event) => callBack(event, name)}
                className={`w-full h-[40px] font-Poppins rounded-[5px] px-3 block border-2 ${isValid ? 'border-gray-400' : 'border-red-500'} outline-none focus:border-black`}
            />
             <label className={`${!isValid ? "block" : "hidden"} text-[10px] font-medium ml-1 text-[#F03947]`}>
        {errorMessage}
      </label>
        </div>
    );
};

export default Input;
