import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import LoginModal from "./LoginModal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//@ts-ignore
import Slider from "react-slick";
import CategoryNavBar from "../Shopping-cart/CategoryNavBar";
import { useNavigate, useParams } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Console } from "console";

import Swal from "sweetalert2";
import { FavoriteRounded } from "@mui/icons-material";
import Pagination from "@mui/material/Pagination";
import { Helmet } from "react-helmet";

const baseUrl = process.env.REACT_APP_BASE_URL;
const defaultLogoUrl =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAO0AAADVCAMAAACMuod9AAAAVFBMVEXu7u5mZmbz8/NZWVlcXFy8vLzU1NSXl5eioqKysrL19fV/f3/x8fFjY2NeXl7Nzc3j4+NqamrKysqMjIx3d3eGhobp6ene3t5TU1Nubm6wsLCPj49E04CSAAACxUlEQVR4nO3b63KiQBRFYWwIJoSbYnQyef/3HPCCIt1OMraTOjtr/VSk+vMolypNkh+U++4F/NfQ6oZWN7S6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDqxta3dDqhlY3tLqh1Q2tbmh1Q6sbWt3Q6oZWN7S6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDq9sDtKWLVfSlxd9j2TzFqo29tge8f89plsao/pWXsdcWeX+Dtn5dxug9M6FNX4oYvaZGtDH2ivbe0N65R7T/ENp7+25tf5kZIOlpS5e//F4nXpScttxt0rrOVo1vezVt2dbVoq9KGw9LTVu87bE9d+F5gZq2zRbHsvXcJaYtm1Fbe16hps1/kjbZjlqfy7bWtdePFsv6eJTaFPPtTWtdk65nD7/vuVW1EzsD9dgqW1+NsEyWWZalb61PZVjr9sff+XSLbdO0/itlu9p+ssfz6uwLWoZEZrVuPLN6vruhrGrPWO90x6bHKqPaS2zP9d7wDJt11eRoZVM7xQan67r+huiSa1J7jQ1Mt8cO90IXXIvaOXbgzqa7x065BrU+rGe6RXe6hjxz7Wn92BnXnbA9d3XimtOGsFfc4oy9mK41rWvqAHbCdd1ks9N0jWnDk51wi+7qPTlybWlvTfaC6z5mmx24prS3JztyZ5MduZa0Lr892QO38Ez2xDWkdfnfJnvg+rF7rh1t8YnJDtVV6JlqlVjRPn1qsrerNhsb2vojOLKvcBc2fkEU/nx+LSPaOFi0964t8v7QokVrU5tmcbLw++Rkl8dqG3tpj/gXRRmr6CvjPyO6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDqxta3dDqhlY3tLqh1Q2tbmh1Q6sbWt3Q6oZWN7S6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDq9sDtKWLVfSlxd9j2TzFqo29tge8f89plsao/pWXsdcWeX+Dtn5dxug9M6FNX4oYvaZGtDH2ivbe0N65R7T/ENp7+25tf5kZIOlpS5e//F4nXpScttxt0rrOVo1vezVt2dbVoq9KGw9LTVu87bE9d+F5gZq2zRbHsvXcJaYtm1Fbe16hps1/kjbZjlqfy7bWtdePFsv6eJTaFPPtTWtdk65nD7/vuVW1EzsD9dgqW1+NsEyWWZalb61PZVjr9sff+XSLbdO0/itlu9p+ssfz6uwLWoZEZrVuPLN6vruhrGrPWO90x6bHKqPaS2zP9d7wDJt11eRoZVM7xQan67r+huiSa1J7jQ1Mt8cO90IXXIvaOXbgzqa7x065BrU+rGe6RXe6hjxz7Wn92BnXnbA9d3XimtOGsFfc4oy9mK41rWvqAHbCdd1ks9N0jWnDk51wi+7qPTlybWlvTfaC6z5mmx24prS3JztyZ5MduZa0Lr892QO38Ez2xDWkdfnfJnvg+rF7rh1t8YnJDtVV6JlqlVjRPn1qsrerNhsb2vojOLKvcBc2fkEU/nx+LSPaOFi0964t8v7QokVrU5tmcbLw++Rkl8dqG3tpj/gXRRmr6CvjPyO6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDqxta3dDqhlY3tLqh1Q2tbmh1Q6sbWt3Q6oZWN7S6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDqxta3dDqhlY39wcAlyz4wLPLJwAAAABJRU5ErkJggg==";

interface Item {
  id: number;
  image: string[];
  name: string;
  price: string;
  originalPrice: string;
  description: string;
}
interface SelectedItem {
  companyId: number;
  batchId: number;
  itemId: number;
  itemName: string;
  quantity: number;
  sellingPrice: number;
  imgURL: string;
}

interface ItemReviewImg {
  id: number;
  imgUrl: string;
}

interface Reviews {
  id: number;
  rating: number;
  review: string;
  isAnonymous: boolean;
  itemId: number;
  webUserFirstName: string;
  webUserLastName: string;
  webUserUsername: string;
  itemReviewImg: ItemReviewImg[];
  active: boolean;
}

const Items: React.FC = () => {
  const [item, setItem] = useState<Item | any>(null);
  const [selectedItem, setSelectedItem] = useState<SelectedItem>();
  const [liked, setLiked] = useState<boolean>(false);
  const [quantity, setQuantity] = useState(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAddCartModal, setShowAddCartModal] = useState<boolean>(false);
  const [cartItem, setCartItem] = useState<Item>({
    id: 0,
    image: [],
    name: "",
    price: "",
    originalPrice: "",
    description: "",
  });
  // State to manage wishlist
  const [isInWishlist, setIsInWishlist] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { companyId, itemId, batchId } = useParams();
  const navigate = useNavigate();

  const [reviews, setReviews] = useState<Reviews[]>([]);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [rowCount, setRowCount] = useState(0);

  const [images, setImages] = useState(item?.image || []);
  const hasFetched = useRef(false);

  useEffect(() => {
    setImages(item?.image || []); // Update images state when item prop changes
  }, [item]);

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      return;
    }
    fetchItemDetails();
  }, [quantity, companyId]);

  // wishlist use
  useEffect(() => {
    if (item) {
      checkIfLiked();
    }
  }, [item]);

  useEffect(() => {
    fetchReviews();
  }, [page, itemId]);

  // useEffect(() => {
  //   fetchReviews();
  // }, [reviews]);

  console.log(quantity);

  const fetchItemDetails = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/webItem/item/${companyId}/${itemId}/${batchId}`
      );
      const itemDetails = response.data.data.item;

      const images = itemDetails.itemImgDTOList.map(
        (imgDTO: any) => imgDTO.imgUrl
      );
      const mainImage = images[0] || defaultLogoUrl;

      setSelectedItem({
        companyId: Number(companyId),
        batchId: itemDetails.batchId,
        itemId: itemDetails.id,
        itemName: itemDetails.itemName,
        quantity: quantity,
        sellingPrice: itemDetails.sellingPrice,
        imgURL: itemDetails.itemImgDTOList[0]?.imgUrl || "",
      });
      console.log("response: ", response);
      setItem({
        id: itemDetails.id,
        // image: itemDetails.itemImgDTOList[0].imgUrl,
        // image: itemDetails.itemImgDTOList && itemDetails.itemImgDTOList.length > 0 ? itemDetails.itemImgDTOList[0].imgUrl : defaultLogoUrl,
        image: images.length > 0 ? images : [defaultLogoUrl],
        name: itemDetails.itemName,
        price: itemDetails.sellingPrice.toString(),
        originalPrice: "", // No original price in the response
        description: "", // No description in the response
        branchId: itemDetails.branchId,
      });
      console.log("item id :", item);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Error fetching item details.");
    }
  };

  //review
  const checkIfLiked = async () => {
    if (!item) return;
    console.log("islike :", item.id);
    const { userId, token } = setStoreUser();

    if (userId && token) {
      try {
        const response = await axios.get(`${baseUrl}/wishList/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const wishList: any[] = response.data.data.wishList;
        console.log("wishlist Items upper: ", wishList);
        // const isLiked = wishList.some(wishlistItem => wishlistItem.name === item.name);
        console.log("item.id", item.id);

        wishList.forEach((e) => {
          if (e.itemId === item.id) {
            setLiked(true);
          }
        });
      } catch (error) {
        console.error("Error checking wishlist status:", error);
      }
    }
  };

  const fetchReviews = async () => {
    try {
      const data = {
        offset: page - 1,
        limit: rowsPerPage,
        columnName: "id",
      };
      console.log("data : ", data);
      axios
        .post(`${baseUrl}/review/itemReviews/${itemId}`, data)
        .then((response) => {
          console.log("response reviews: ", response);
          if (response.status === 200 && response.data.data) {
            setReviews(response.data.data.reviews);
            setRowCount(response.data.data.rowCount || 0);
            console.log("response : ", response);
          } else {
            console.error("No companies found in response");
          }
        });
    } catch (error) {
      console.error("There was an error filtering the companies!", error);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    // fetchReviews();
  };

  console.log("items: ", item);

  const setStoreUser = (): { userId: string | null; token: string | null } => {
    const storeUser = sessionStorage.getItem("user");

    if (storeUser) {
      const user = JSON.parse(storeUser);
      const userId = user.id;
      const token = user.token;

      console.log("UserId : ", userId);
      console.log("Token : ", token);

      return { userId, token };
    }

    return { userId: null, token: null };
  };

  //wishList check user
  const checkSession = (): boolean => {
    const storeUser = sessionStorage.getItem("user");
    return storeUser !== null;
  };

  const handleLike = async () => {
    // if (!item) return;

    const { userId, token } = setStoreUser();

    if (userId && token) {
      // Add to wishlist
      const wishlistItem = {
        id: 0,
        isAvailable: true,
        itemId: itemId,
        itemName: item.name,
        // imgURL: item.image || defaultLogoUrl,
        imgURL: item?.image[0] ? item.image[0] : [defaultLogoUrl],
        batchId: batchId,
      };

      try {
        if (liked) {
          await axios.post(
            `${baseUrl}/wishList/wishListItem/${userId}`,
            wishlistItem,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          setLiked(false);
          console.log("item already in wishlist");
        } else {
          console.log(`Adding ${item.name} to wishlist...`);

          // console.log("Wishlist item:", wishlistItem);

          await axios.post(
            `${baseUrl}/wishList/wishListItem/${userId}`,
            wishlistItem,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          setLiked(true);
          console.log(`Successfully added ${item.name} to wishlist`);
        }

        // setLiked(!liked);
      } catch (error) {
        console.error("Error updating wishlist:", error);
      }
    } else {
      console.log("User is not logged in");
      // setShowModal(true);
    }
  };

  //add to cart

  const handleAddToCart = async () => {
    const { userId, token } = setStoreUser();

    if (token && userId) {
      setShowModal(false);
      const data = {
        id: null,
        quantity,
        itemId: itemId,
        batchId: batchId,
        companyId: companyId,
        branchId: item.branchId,
      };

      try {
        const response = await axios.post(
          `${baseUrl}/cart/cartItem/${userId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        Swal.fire({
          title: "Success",
          text: "Item added to cart successfully!",
          icon: "success",
          confirmButtonText: "OK",
        });
        console.log("Item added to cart:", response.data);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Error adding item to cart. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Error adding item to cart:", error);
      }
    } else {
      setShowModal(true);
    }
    // addItemToCart();
  };

  const increaseQuantity = (event: any) => {
    event.preventDefault();
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = (event: any) => {
    event.preventDefault();
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const handleBuyNow = () => {
    if (checkSession()) {
      if (item && item.branchId) {
        setShowModal(false);
        window.scrollTo(0, 0);
        navigate(`/shoppingCartItem/${companyId}/${item.branchId}/checkout`, {
          state: { selectedItem },
        });
      } else {
        console.error("Branch ID is not available in the item object.");
      }
    } else {
      setShowModal(true);
    }
  };

  const handleShowItems = () => {
    if (checkSession()) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  };

  const handleShowCart = () => {
    if (checkSession()) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowAddCartModal = () => {
    if (!item) return;
    setShowAddCartModal(true);
    setCartItem(item);
  };

  // const handleCloseAddCartModal = () => {
  //   setShowAddCartModal(false);
  // };

  // const handleDelete = (name: string) => {
  //   setWishlist(wishlist.filter(item => item.name !== name));
  // };

  // const handleAddToCart = (name: string) => {
  //   const item = wishlist.find(item => item.name === name);
  //   if (item) {
  //     setShowAddCartModal(true);
  //     setCartItem(item);
  //   }
  // };

  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Enable infinite scrolling
    speed: 500, // Transition speed
    slidesToShow: Math.min(images.length, 3), // Show 3 slides at once
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 3000, // Auto-scroll speed (in milliseconds)
    responsive: [
      // Adjust settings for different screen sizes
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(images.length, 3),
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(images.length, 2),
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(images.length, 1),
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!item) {
    return <div>No item found.</div>;
  }

  // //wishList check user
  // const checkSession = () => {
  //   const token = sessionStorage.getItem('token');
  //   const user = sessionStorage.getItem('user');
  //   if (token && user) {
  //     const userData = JSON.parse(user);
  //     return userData.id !== null && userData.id !== undefined;
  //   }
  //   return false;
  // }

  const handleThumbnailClick = (index: number) => {
    if (images.length > 0) {
      const newImages = [...images];
      const clickedImage = newImages.splice(index, 1)[0]; // Remove the clicked image
      newImages.unshift(clickedImage); // Add it to the start of the array
      setImages(newImages); // Update the state with the new image order
    }
  };

  return (
    <>
      {/* <Helmet>
        <title>Pet Medi Web - Items</title>
        <meta
          name="description"
          content="Learn more about Pet Medi, your trusted pet health partner."
        />
        <meta
          name="keywords"
          content="pet, health, care, dogs, cats, veterinary,Pet clinic in Sri Lanka,Veterinary services Sri Lanka,Pet hospital,Affordable pet care Sri Lanka, 
      Veterinarian near me,Animal clinic Sri Lanka,Pet surgery ,Pet vaccinations Sri Lanka,24/7 emergency vet Sri Lanka, Pet health checkup Sri Lanka, Dog clinic Sri Lanka, 
      Cat veterinary care Sri Lanka, Exotic pet care Sri Lanka, Pet dental care Sri Lanka, Pet grooming, Pet boarding services Sri Lanka, Best vet for pet Sri Lanka, 
      Cataract surgery for pets Sri Lanka, Animal rescue services Sri Lanka ,Veterinary clinic , Sri Lanka , Pet Medi, pet, P , Sri Lanka Pets, Pet Care, Animal Clinic, 
      Vet, Pet Health, Pet Health Checkup, veterinary,Pet dental care, Grooming, Best Animal Clinic, Pet health monitoring app, Veterinary telemedicine Sri Lanka,Pet prescription management,
      Pet weight management Sri Lanka,Pet nutrition advice Sri Lanka,Digital veterinary solutions,Pet clinic appointment system,Senior pet care services,Specialized pet care Sri Lanka,
      Online vet consultation Sri Lanka,Pet illness prevention,Remote vet consultations,Pet healthcare software,Preventive vet care Sri Lanka,Pet emergency hotline Sri Lanka,
      Advanced pet diagnostics,Pet care plans Sri Lanka,In-home veterinary services,Pet surgery aftercare Sri Lanka,Pet grooming and spa Sri Lanka,Veterinary lab testing Sri Lanka,
      Personalized pet healthcare plans,Vet treatment packages Sri Lanka,Animal health solutions Sri Lanka,Vet treatment plans Sri Lanka,Pet physiotherapy Sri Lanka, 
      Vets near me, cat clinic near me, dog clinic near me, rabbit consultant doctors, dog consultant doctors, cat consultant doctors, online pet app, pet app online, 
      app for pets, app for animals, animal doctor, pet doctor, pet consultant, Pet wellness programs Sri Lanka, Pet wellness programs, Veterinary nutrition consultation, 
      Pet behavior training Sri Lanka, Pet behavior training, Veterinary dermatology Sri Lanka, Veterinary dermatology, Puppy vaccinations, Puppy vaccinations Sri Lanka, 
      Kitten vaccinations Sri Lanka, Kitten vaccinations, Flea and tick prevention Sri Lanka, Flea and tick prevention, Microchipping services Sri Lanka, Pet allergy treatment Sri Lanka, Pet allergy treatment,
      Veterinary orthopedic surgery Sri Lanka,Veterinary orthopedic surgery, 24-hour vet service Sri Lanka, 24-hour vet service , Emergency pet care Sri Lanka, Emergency pet care,
      Animal eye care Sri Lanka,Animal eye care,Pet dental cleaning Sri Lanka,Pet dental cleaning,Dog vaccinations Sri Lanka, Dog vaccinations,Cat vaccinations Sri Lanka,Cat vaccinations,Animal diagnostic imaging,
      Vet appointment booking online Sri Lanka,Vet appointment booking online,Pet Surgery Sri Lanka, Pet Surgery,Specialist vet services Sri Lanka,Specialist vet services,Pet cardiologist Sri Lanka, Pet cardiologist,
      Best dog vet Sri Lanka,Best dog vet, Best cat vet Sri Lanka, Best cat vet,Pet health checkup Sri Lanka, Pet health checkup, Puppy health checkup Sri Lanka,Puppy health checkup, Kitten health checkup Sri Lanka,Kitten health checkup,
      Emergency pet clinic near me,Pet health and wellness Sri Lanka,Pet health and wellness , pet treatment,Pet Health, Pet Services,Pet Services near me,Pet health diagnosis,Veterinary diagnosis,Common pet diseases,Animal health conditions,
      Dog illness diagnosis,Cat health problem, Dog health problem, Pet health problem, Pet disease symptoms, Veterinary medical conditions, Signs of illness in pets, Pet diagnostic tests,
      Dog allergy diagnosis, Cat urinary tract infection symptoms, Dog urinary tract infection symptoms, Pet urinary tract infection symptoms, Veterinary blood tests for pets, Pet arthritis treatment,
      Dog diabetes diagnosis, Cat diabetes diagnosis, Pet diabetes diagnosis, Pet cancer signs, Common pet skin diseases, Animal ear infections, Dog digestive problems diagnosis,
      Cat digestive problems diagnosis, Pet digestive problems diagnosis, Pet liver disease symptoms, Kidney disease in cats, Kidney disease in dogs, Kidney disease in pets,
      Pet obesity treatment, Dog heart disease symptoms, Cat heart disease symptoms, Pet heart disease symptoms, Cat thyroid disease, Dog thyroid disease, Pet thyroid disease,
      Pet parasite infections, Veterinary checkup for pets, Dog hip dysplasia diagnosis, Cat respiratory infections, Dog respiratory infections, Pet respiratory infections, Pancreatitis in pet symptoms, Dog Food, Cat Food"
    />
      </Helmet> */}

      <div>
        <div className="min-h-screen flex items-center justify-center bg-gray-100 px-5 md:px-10 lg:px-14 lg:pt-10 pb-20 itemDetail">
          <div className="absolute top-0 w-full z-10">
            <CategoryNavBar
              isDropdownOpen={false}
              handleDropdownToggle={() => { }}
            />
          </div>

          <div className="bg-white shadow-lg rounded-lg overflow-hidden grid grid-cols-1 md:grid-cols-2 w-full h-full mt-40 md:mt-44 lg:mt-32">
            {/* Left side */}
            <div className="flex flex-col p-4">
              <div className="flex justify-center items-center">
                <img
                  src={images[0]}
                  alt="Item"
                  className="w-full h-auto object-cover transition-all duration-500 ease-in-out"
                />
              </div>

              {images.length > 1 && (
                <div className="mt-4 px-3 py-4 gap-4">
                  {/* Small thumbnails */}
                  <Slider {...settings}>
                    {/* <img src={item?.image[0] || defaultLogoUrl} alt="Thumbnail" className="w-32 h-32 object-cover border img-fluid" />
                <img src={item?.image[1] || defaultLogoUrl} alt="Thumbnail" className="w-32 h-32 object-cover border img-fluid" />
                <img src={item?.image[2] || defaultLogoUrl} alt="Thumbnail" className="w-32 h-32 object-cover border img-fluid" />
                <img src={item?.image[3] || defaultLogoUrl} alt="Thumbnail" className="w-32 h-32 object-cover border img-fluid" />
                <img src={item?.image[4] || defaultLogoUrl} alt="Thumbnail" className="w-32 h-32 object-cover border img-fluid" /> */}
                    {images.slice(0, 4).map((image: any, index: any) => (
                      <img
                        key={index}
                        src={image || defaultLogoUrl}
                        alt={`Thumbnail ${index + 1}`}
                        className="object-contain w-screen h-28 items-center justify-center cursor-pointer border gap-4"
                        onClick={() => handleThumbnailClick(index)}
                      />
                    ))}
                  </Slider>
                </div>
              )}
            </div>

            {/* Right side */}
            <div className="flex flex-col p-6">
              <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold mb-10">
                {item.name}
              </h1>
              <div className="flex items-center mb-2">
                <span className="text-xl font-bold text-blue-600">
                  {item.price}.00 LKR
                </span>
              </div>
              <div className="mb-4">
                <span className="text-sm">{item.description} </span>
              </div>
              <div className="flex items-center mb-4 mt-10">
                <button
                  className="bg-blue-600 text-white py-2 px-4 rounded mr-2 w-full md:w-auto"
                  onClick={() => handleBuyNow()}
                >
                  Buy Now
                </button>

                <button
                  className="hidden md:block border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white py-2 px-4 mr-2 rounded"
                  onClick={() => {
                    handleShowCart();
                    handleAddToCart();
                  }}
                >
                  Add to Cart
                </button>
                <button
                  className="block md:hidden text-blue-600 hover:text-blue-700 text-2xl mr-2"
                  onClick={() => {
                    handleShowCart();
                    handleAddToCart();
                  }}
                >
                  <i className="bi bi-cart-plus"></i>
                </button>
                <button
                  onClick={() => {
                    handleLike();
                    handleShowItems();
                  }}
                >
                  <FavoriteRounded
                    className={`${liked ? "text-red-500" : "text-gray-700 "}`}
                  />
                </button>
              </div>

              <div className="flex items-center mb-4">
                <span className="text-lg font-medium">Quantity</span>
                <div className="flex items-center ml-4">
                  <button
                    className="border border-gray-400 text-gray-600 px-2"
                    onClick={decreaseQuantity}
                  >
                    -
                  </button>
                  <span className="px-4">{quantity}</span>
                  <button
                    className="border border-gray-400 text-gray-600 px-2"
                    onClick={increaseQuantity}
                  >
                    +
                  </button>
                </div>
              </div>

              <div className="p-4">
                <div className="mb-4">
                  <div className="flex items-center justify-between mb-2">
                    <h2 className="text-lg font-semibold">Delivery</h2>
                  </div>
                  <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center">
                      <span className="material-icons text-gray-500 mr-2">
                        <i className="bi bi-geo-alt-fill"></i>
                      </span>
                      <span>Western, Colombo 1-15, Colombo 01 - Fort</span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center">
                      <span className="material-icons text-gray-500 mr-2">
                        <i className="bi bi-currency-dollar"></i>
                      </span>
                      <br />
                      <span>Standard Delivery 6 - 7 day(s)</span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center">
                      <span className="material-icons text-gray-500 mr-2">
                        <i className="bi bi-currency-dollar"></i>
                      </span>
                      <br />
                      <span>Rs. 250</span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <span className="material-icons text-gray-500 mr-2">
                        <i className="bi bi-cash-coin"></i>
                      </span>
                      <span>Cash on Delivery Available</span>
                    </div>
                  </div>
                </div>

                <div className="flex space-x-2 mt-4">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <i
                      key={star}
                      className={`bi bi-star${rowCount >= star ? "-fill" : ""
                        } text-yellow-500 cursor-pointer hover:text-yellow-500`}
                    />
                  ))}
                  <a href="reviews">
                    <p className="text-blue-700 font-semibold">
                      {rowCount} Reviews
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LoginModal
          show={showModal}
          handleClose={handleCloseModal}
          navigatePath={`/shoppingCartItem/item/${companyId}/${itemId}/${batchId}`}
        />

        <div
          className="reviews px-5 md:px-10 lg:px-14 bg-gray-100"
          id="reviews"
        >
          <div className="bg-white shadow-lg py-4 rounded-lg px-2">
            <h2 className="text-gray-700 text-sm font-medium py-2">
              Product Reviews
            </h2>
            {reviews.map((review: any) => (
              <div key={review.id} className="review border-t-2 ">
                <div className="flex justify-between w-full p-4 h-2/3">
                  <div className="flex flex-col justify-center text-start">
                    <div className="flex space-x-2">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <i
                          key={star}
                          className={`bi bi-star${review.rating >= star ? "-fill" : ""
                            } text-yellow-500 cursor-pointer hover:text-yellow-500`}
                        />
                      ))}
                    </div>

                    <div className="mt-2 font-semibold">
                      <p>
                        User:{" "}
                        <span className="text-gray-700 text-sm">
                          {review.webUserFirstName} {review.webUserLastName}
                        </span>
                      </p>
                    </div>
                    <div className="mt-2 font-semibold">
                      <p>
                        Review:{" "}
                        <span className="text-gray-700 text-sm">
                          {review.review}
                        </span>
                      </p>
                    </div>

                    <div className="flex justify-start space-x-5 mt-4">
                      {review.itemReviewImg.map((img: any) => (
                        <div className="w-full h-1/3">
                          <img
                            key={img.id}
                            src={img.imgUrl || defaultLogoUrl}
                            alt="Review"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-wrap justify-center items-center pb-2 md:pb-4 mt-2 md:mt-4 space-y-2 md:space-y-0">
            <span className="text-blue-600 text-sm md:text-lg mr-0 md:mr-4">
              Page: {page}
            </span>
            <div className="flex flex-col md:flex-row items-center space-y-2  md:space-y-0 md:space-x-4">
              <Pagination
                count={Math.ceil(rowCount / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Items;
