import React from 'react';
import "./loading.css";

interface LoadingProps {
    show: boolean;
}

const Loading: React.FC<LoadingProps> = ({ show }) => {
    if (!show) return null;

    return (
        <div className="loading-overlay">
            <div className="circle-wrap overflow-hidden">
                <div className="circle">
                    <div className="mask full">
                        <div className="fill"></div>
                    </div>
                    <div className="mask half">
                        <div className="fill"></div>
                    </div>
                    <div className="inside-circle">
                        <img

                            src="/favicon.png"
                            alt="Loading Icon"
                            className="loader-image"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loading;
