import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import LoginModal from '../Items/LoginModal';
import {
  ArrowDropDown,
  ArrowDropUp,
  PowerSettingsNew,
  Search,
  ShoppingCart,
  Pets,
  AssignmentTurnedInRounded,
  FavoriteRounded,
} from '@mui/icons-material';

const baseUrl = process.env.REACT_APP_BASE_URL;

interface User {
  id: string;
  name: string;
  token: string;
  role: string;
  companyId: string | null;
  needMetaData: string | null;
  profilePicture: string;
  email: string;
  familyName: string;
  givenName: string;
  googleId: string;
  imageUrl: string;
}

const CategoryNavBar = ({

  isClick,
  isDropdownOpen,
  handleDropdownToggle,
  handleCartClick,
  fetchItemsWithSearch = () => {},
  isClearText,

}: {

  isClick?: boolean;
  isDropdownOpen?: boolean;
  handleDropdownToggle?: () => void;
  handleCartClick?: () => void;
  fetchItemsWithSearch?: (data: any) => void;
  isClearText?: boolean;

}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const encodedSearchTerm = queryParams.get('search');
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { companyId } = useParams();
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [searchTextTerm, setSearchTextTerm] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const defaultProfilePicture = 'https://www.gravatar.com/avatar?d=mp';
  const [user, setUser] = useState<User | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  // Decode
  const searchTerm = encodedSearchTerm ? decodeURIComponent(encodedSearchTerm) : null;

  useEffect(() => {
    if (searchTerm !== null) {
      setSearchTextTerm(searchTerm);
    }else{
      setSearchTextTerm('');
    }
  }, [searchTerm]);

  const setStoreUser = (): { userId: string | null; auth: string | null } => {
    const storeUser = sessionStorage.getItem('user');
    if (storeUser) {
      const user = JSON.parse(storeUser);
      const userId = user.id;
      const auth = user.token;
      return { userId, auth };
    }
    return { userId: null, auth: null };
  };

  const { userId, auth } = setStoreUser();

  //close login model
  const handleCloseModal = () => {
    setShowModal(false);
    
  };

  const handleCloseProfileModal = () => {
    setShowProfileModal(false);
  }

  const clickHandleCart = () => {
    if (auth && userId) {
      navigate(`/shoppingCartItem/${companyId}/cart`);
    } else {
      setShowModal(true);
    }
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  //log out with click cart logout button
  const handleLogout = () => {
    setUser(null);
    sessionStorage.removeItem('user');
    // window.location.reload();
    navigate(`/shoppingCartItem/${companyId}`);
  };

  const handleCart = () => {
    if (auth && userId) {
      axios
        .get(`${baseUrl}/cart/${userId}`, {
          headers: {
            Authorization: `Bearer ${auth}`,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            setCartItemsCount(response.data.data.count || 0);
          } else {
            console.error('Unexpected response status:', response.status);
          }
        })
        .catch((error) => {
          console.error('Error fetching cart data:', error);
        });
    }
  };


  useEffect(() => {
    handleCart();
  }, [isClick]);

  //set data with mount component
  useEffect(() => {
    handleCart();
  }, []);

  //handle navigate to wish list
  const handleWishlistClick = () => {
    if (auth && userId) {
      navigate(`/shoppingCartItem/${companyId}/wishlist`);
    } else {
      setShowModal(true);
    }
  };

  const handleMyOrderClick = () => {
    if (auth && userId) {
      navigate(`/shoppingCartItem/${companyId}/${userId}/myOrders`);
    } else {
      setShowModal(true);
    }
  }

 //handle outside click in the user drop down
 useEffect(() => {
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current && !dropdownRef.current.contains(event.target as Node) ){
        setOpen(false);
      }
      if(suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)){
        setShowSuggestions(false);
      }
  };

  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

  //search suggestions
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
   
    setSearchTextTerm(query);

    if (query.length > 0) {
      axios
        .get(`${baseUrl}/search/suggestions/${query}/${companyId}`) 
        .then((response:any) => {
          if (response.status === 200) {
            setSuggestions(response.data.data.suggestions || []);
            setShowSuggestions(true);
          } 
        })
        .catch((error) => {
          console.error('Error fetching suggestions:', error);
        });
    } else {
      setShowSuggestions(false);
    }
  };

  // click on the suggestion
  const handleSuggestionClick = (suggestion: any) => {
    setSearchTextTerm(suggestion.name);
    setShowSuggestions(false);
    searchInputRef.current?.focus();

    //encode and pass name to api
    const encodedTerm = encodeURIComponent(suggestion.name);
    navigate(`/shoppingCartItem/search/${companyId}?search=${encodedTerm}`);
    window.location.reload();

  };

  //search enter 
  const handleSearchSubmit = (searchTerm: any) => {
  
    if (searchTerm.trim() !== '') {
      setShowSuggestions(false);
      performSearch(searchTerm);
    }

  };
  const performSearch = (term: string) => {

    // Encode 
    const encodedTerm = encodeURIComponent(term);

    // Navigate to the search results page with the encoded term
    navigate(`/shoppingCartItem/search/${companyId}?search=${encodedTerm}`);
    window.location.reload();
  };
  
  return (
    <div>
      <div className="relative bg-blue-700 pt-20 md:pt-20 lg:pt-24 px-6 xl:px-16 md:px-12 lg:px-16">
        <div className="flex justify-between items-center pb-5 md:pb-4">
          <button
            className="hidden md:flex items-center gap-2 bg-white text-blue-700 rounded-md px-2 py-1 text-sm font-medium"
            onClick={handleDropdownToggle}
          >
            <Pets />
            All Categories
            {isDropdownOpen ? <ArrowDropUp /> : <ArrowDropDown />}
          </button>
          <div className="md:hidden flex items-center text-white pr-2">
            <Pets onClick={handleDropdownToggle} />
          </div>
          <div className="w-48 sm:w-80 md:w-96">
            <div className="relative" ref={suggestionsRef}>
              <input
                type="search"
                ref={searchInputRef}
                className="w-full h-9 rounded-md bg-white pl-3 pr-10 text-sm focus:outline-none"
                placeholder="Search..."
                value={searchTextTerm}
                onChange={handleSearchChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSearchSubmit(searchTextTerm);
                  }
                }}
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => handleSearchSubmit(searchTextTerm)}
              >
                <Search />
              </div>
              {showSuggestions && (
                <div className="absolute left-0 right-0 top-10 bg-white border border-gray-200 rounded-md shadow-lg z-10">
                  {suggestions.length > 0 ? (
                    suggestions.map((suggestion:any, index) => (
                      <div
                        key={index}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion.name}
                      </div>
                    ))
                  ) : (
                    <div className="p-2 text-gray-500">Search Item Not Found</div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-5 text-white">
            <div className="relative">
              <button className="relative pl-1" onClick={clickHandleCart}>
                <ShoppingCart />
                {cartItemsCount > 0 && (
                  <span className="absolute -top-2 -right-2 h-4 w-4 rounded-full bg-white text-xs text-blue-700 flex items-center justify-center">
                    {cartItemsCount}
                  </span>
                )}
              </button>
            </div>
            {user ? (
              <img
                className="w-8 h-8 rounded-full cursor-pointer border border-black hover:border-white"
                src={user.profilePicture || defaultProfilePicture}
                alt="Profile"
                onClick={() => setOpen(true)}
              />
            
            ) : (
              <button
                className="bg-white text-blue-700 rounded-md px-2 py-1 text-[10px] md:text-sm font-medium"
                onClick={() => setShowProfileModal(true)}
              >
                Sign In
              </button>
            )}

          </div>
        </div>
      </div>

      {open && (
        <div
          ref={dropdownRef}
          className="absolute right-1  w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
        >
          <div
            className="py-1 flex flex-col items-center"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <button
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center w-full justify-center"
              role="menuitem"
              onClick={handleMyOrderClick}
            >
              <AssignmentTurnedInRounded className="mr-2" />
              My Order
            </button>
            <button
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center w-full justify-center"
              role="menuitem"
              onClick={handleWishlistClick}
            >
              <FavoriteRounded className="mr-2" />
              Wish List
            </button>
            <button
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center w-full justify-center"
              role="menuitem"
              onClick={handleLogout}
            >
              <PowerSettingsNew className="mr-2" />
              Logout
            </button>
          </div>
        </div>
      )}
      <LoginModal show={showProfileModal} handleClose={handleCloseProfileModal}/>
      <LoginModal show={showModal} handleClose={handleCloseModal} navigatePath={`/shoppingCartItem/${companyId}/cart`} />
      
    </div>
  );
};

export default CategoryNavBar;