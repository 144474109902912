import React, { useEffect, useState } from "react";
//@ts-ignore
import Slider from 'react-slick';
import { AutoAwesome } from "@mui/icons-material";
import ItemCard from "../../component/card/ItemCard";
import { useParams } from "react-router-dom";
import axios from "axios";
import TopItemCard from "../../component/card/TopItemCard";

const baseUrl = process.env.REACT_APP_BASE_URL;

const HotItems = () => {
  const [topSellingItems, setItems] = useState([]);
  const { companyId } = useParams();

  useEffect(() => {
    axios.get(`${baseUrl}/webItem/topItems/${companyId}`)
      .then(response => { 
        console.log(response);
        if (response.status === 200) {
          setItems(response.data.data.topSellingItems);
        } else {
          console.error('No items found in response');
        }
      }).catch(err => {
        console.log(err);
      });
  }, [companyId]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: Math.min(6, topSellingItems.length),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      { breakpoint: 1900, settings: { slidesToShow: Math.min(5, topSellingItems.length), slidesToScroll: 1, infinite: true } },
      { breakpoint: 1500, settings: { slidesToShow: Math.min(4, topSellingItems.length), slidesToScroll: 1, infinite: true } },
      { breakpoint: 1200, settings: { slidesToShow: Math.min(3, topSellingItems.length), slidesToScroll: 1, infinite: true } },
      { breakpoint: 900, settings: { slidesToShow: Math.min(2, topSellingItems.length), slidesToScroll: 1 } },
      { breakpoint: 580, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  return (
    <div className="flex-1 pt-5 pb-5">
      <div className="flex items-center space-x-2 mb-4">
        <AutoAwesome className="text-blue-500" />
        <h2 className="text-2xl font-bold">Hot Items</h2>
      </div>
      {topSellingItems.length > 0 ? (
        <Slider {...settings}>
          {topSellingItems.map((slide, index) => (
            <div key={index} className="p-2">
              <TopItemCard slide={slide} />
            </div>
          ))}
        </Slider>
      ) : (
        <div className="text-center mt-5 mb-5">No hot items available.</div>
      )}
    </div>
  );
};

export default HotItems;
