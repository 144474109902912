import React, { ChangeEvent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import Login from './Login';
import { gapi } from 'gapi-script';
import { log } from 'console';
import SignUpPage from './SingUpPage';

import Swal from 'sweetalert2';
import ForgotPassword from "./ForgotPassword";


const baseUrl = process.env.REACT_APP_BASE_URL;

const clientId="1023979584830-f19944uv2q8j0u6fegft41bm4si8fhci.apps.googleusercontent.com"

interface LoginModalProps {
  show: boolean;
  handleClose: () => void;
  navigatePath?: string;
}



const LoginModal: React.FC<LoginModalProps> = ({ show, handleClose, navigatePath}) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showSignUp, setShowSignUp] = useState<boolean>(false);
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);



  const navigate = useNavigate();

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: ""
      });
    }

    gapi.load('client:auth2', start);
  }, [] );



  const saveLoggedUserDetails = async (data:any, provider: string, profilePicture?: string) => {
    console.log('Saving user details:', data);
    console.log('Received data.data:', data.data);

    return new Promise((resolve) => {
      const user = {
        id: data.data.id || data.id,
        name: data.data.name || data.firstName + ' ' + data.lastName,
        email: data.data.email || data.email,
        token: data.data.token || data.token,
        provider: provider,
        profilePicture: profilePicture ? profilePicture : '',
      }
      sessionStorage.setItem('user', JSON.stringify(user));
      console.log('User saved in session:' , user);
      resolve(true);
      console.log('Profile Picture URL:', data.data.profilePicture || data.profilePicture);
    });
  };


  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    if (id === 'email') setEmail(value);
    if (id === 'password') setPassword(value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await fetch(`${baseUrl}/webAuth/login`, {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({ email, password }),
      });
      console.log(response)

      if (response.ok) {

        const data = await response.json();
        console.log('API Response Data:', data);

        // const result = await saveLoggedUserDetails(data, 'manual');
        await saveLoggedUserDetails(data, 'manual');
        setSuccess('Login successful!');
        setError(null);
        //navigate('/check');
        Swal.fire('Success', 'Login successful!', 'success');
        if(navigatePath !== undefined){
          navigate(`${navigatePath}`);
        }

        window.location.reload();
        console.log('User details saved successfully');

      } else {
        const errorData = await response.json();
        setError(errorData.message);
        setSuccess(null);
        Swal.fire('Error', errorData.message, 'error');
        navigate('/');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
      setSuccess(null);
      Swal.fire('Error', 'An error occurred. Please try again.', 'error');
    }
  };

  const postLoginData = async (data: any, provider: string) => {
    try {
      const response = await fetch(`${baseUrl}/webAuth/third_party_login/${provider}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      console.log("Responce Provider", response);
      if (response.ok) {
        const responseData = await response.json();
        console.log('Response data from server:', responseData);
        return responseData;
      } else {
        const errorData = await response.json();
        console.error('Failed to post login data:', errorData);
        Swal.fire('Error', errorData.message, 'error');
        return null;
      }
    } catch (error) {
      console.error('Error posting login data:', error);
      Swal.fire('Error', 'An error occurred while posting login data.', 'error');
      return null;
    }
  };


  const handleGoogleLoginSuccess = async (response: any) => {
    console.log('Google Login Success:', response);
    const strongPassword = `${response.profileObj.givenName}${response.googleId}${response.profileObj.familyName}`;
    console.log("Strong Password: ", strongPassword);
    const userData = {

        id: null,
        firstName: response.profileObj.givenName,
        lastName: response.profileObj.familyName,
        password: strongPassword,
        email: response.profileObj.email,
        token: response.googleId,
        profilePicture: response.profileObj.imageUrl,

    };
    console.log("User Data" , userData);

    const data = await postLoginData(userData, 'google');
    console.log("Google Data" , data);
    if (data) {
      await saveLoggedUserDetails(data, 'google', userData.profilePicture);
      setSuccess('Google login successful!');
      setError(null);
      Swal.fire('Success', 'Google login successful!', 'success');
      if(navigatePath !== undefined){
        navigate(`${navigatePath}`);
      }
      window.location.reload();
    } else {
      setError('Google login failed.');
      setSuccess(null);
      Swal.fire('Error', 'Google login failed.', 'error');
    }
  };

  const handleGoogleLoginFailure = (error: any) => {
    console.log('Google Login Failure:', error);
    setError('Google login failed.');
    setSuccess(null);
    Swal.fire('Error', 'Google login failed.', 'error');
    navigate('/');
  };


  const handleFacebookLoginSuccess = async (response: any) => {
    console.log('Facebook Login Success:', response);

    const strongPassword = `${response.data.first_name}${response.data.id}${response.data.last_name}`;


    const userData = {
        id: null,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        password: strongPassword,
        email: response.data.email,
        token: response.data.id,
      //   mobileNumber: '',
      // address: '',
      // tryCount: 0,
      // active: true,
      // locked: false,
        profilePicture: response.data.picture ? response.data.picture.data.url : null,

    };
    const data = await postLoginData(userData, 'facebook');
    console.log("Fb Data :" , data);
    if (data) {
      await saveLoggedUserDetails(data, 'facebook', userData.profilePicture );
      setSuccess('Facebook login successful!');
      setError(null);
      Swal.fire('Success', 'Facebook login successful!', 'success');
      if(navigatePath !== undefined){
        navigate(`${navigatePath}`);
      }
      window.location.reload();
    } else {
      setError('Facebook login failed.');
      setSuccess(null);
      Swal.fire('Error', 'Facebook login failed.', 'error');
    }

  };

  const handleFacebookLoginFailure = (error: any) => {
    console.log('Facebook Login Failure:', error);
    setError('Facebook login failed.');
    setSuccess(null);
    Swal.fire('Error', 'Facebook login failed.', 'error');
    navigate('/');
  };

  const toggleSignUp = () => {
    setShowSignUp(!showSignUp);
  };


  if (!show) {
    return null;
  }
  const handleForgotPasswordClick = () => {
    setShowForgotPassword(true);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out">
      <div className="bg-white rounded-lg shadow-lg w-[40rem] p-8 transform transition-transform duration-300 ease-in-out">
        <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">
            {showSignUp ? 'Sign Up' : showForgotPassword ? 'Forgot Password ?' : 'Login'}
          </h2>
          <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 text-2xl transition duration-300 ease-in-out">&times;</button>
        </div>


        {showForgotPassword ? (
            <ForgotPassword onClose={() => setShowForgotPassword(false)} />
        ) : showSignUp ? (
            <SignUpPage />
        ) : (
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">E-Mail</label>
                <input
                    type="email"
                    id="email"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-blue-600 transition duration-300 ease-in-out"
                    placeholder="Enter E-Mail"
                    value={email}
                    onChange={handleChange}
                    required
                />
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                <div className="relative">
                  <input
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-blue-600 transition duration-300 ease-in-out"
                      placeholder="Enter Password"
                      value={password}
                      onChange={handleChange}
                      required
                  />
                  <button type="button" onClick={togglePasswordVisibility}
                          className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm text-gray-500">
                    {showPassword ? 'Hide' : 'Show'}
                  </button>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <button type="submit"
                        className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out">
                  Login
                </button>
              </div>
              <div className="inline-block text-center mt-2 row-auto">
                <button
                    type="button"
                    onClick={handleForgotPasswordClick}
                    className="text-blue-600 hover:underline transition duration-300 ease-in-out"
                >
                  Forgot Password?
                </button>
              </div>
            </form>
        )}


        {!showSignUp && !showForgotPassword && (
            <>

              <div className="text-center my-4">
                <div className="relative">
                  <div className="absolute left-0 top-1/2 w-2/5 h-0.5 bg-gray-200"></div>
                  <span className="bg-white px-4 text-gray-500">or</span>
                  <div className="absolute right-0 top-1/2 w-2/5 h-0.5 bg-gray-200"></div>
          </div>
        </div>
        <div className="text-center">

{/* LogIn */}
          <div className='w-full flex flex-col'>
              <Login onSuccess={handleGoogleLoginSuccess} onFailure={handleGoogleLoginFailure}/>
          </div>


{/* fb login */}
          <div className='w-full flex flex-col mt-4'>
            <LoginSocialFacebook
            appId="837973675061756"
            fieldsProfile="id,first_name,last_name,email,picture"
            onResolve={handleFacebookLoginSuccess}
              onReject={handleFacebookLoginFailure}
            className='w-full text-center rounded shadow-lg'
            >
              <FacebookLoginButton/>
            </LoginSocialFacebook>
          </div>

          <p className="text-gray-500 text-sm mt-4">
                New member?{' '}
                <button onClick={toggleSignUp} className="text-blue-600 hover:underline">
                  Register here
                </button>
              </p>

        </div>
        </>
)}


        {showSignUp && (
          <p className="text-gray-500 text-sm mt-4 text-center">
            Already a member?{' '}
            <button onClick={toggleSignUp} className="text-blue-600 hover:underline">
              Login here
            </button>
          </p>
        )}
      </div>
    </div>
  );
};

export default LoginModal;
