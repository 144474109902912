import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;

interface BrandDetails {
  brandId: number;
  name: string;
}

interface BrandListProps {
  brandDetails?: BrandDetails[];
  isSearch?: boolean;
  isSetEnter?: boolean;
  onSelectedBrandId?: (brandName: string) => void;
}

const FilterBrandBar = ({
  brandDetails,
  isSearch,
  isSetEnter,
  onSelectedBrandId,
}: BrandListProps) => {
  const [listHeight, setListHeight] = useState("auto");
  const listRef = useRef<HTMLUListElement>(null);
  const [activeCategories, setActiveCategories] = useState<{
    [key: number]: boolean;
  }>({});
  const [brands, setBrands] = useState<BrandDetails[]>([]);
  const { companyId, categoryId } = useParams();
  const [noItems, setNoItems] = useState(false);
  const navigate = useNavigate();

  // Adjust the height of the list based on the number of brands
  useEffect(() => {
    if (listRef.current) {
      const height = listRef.current.scrollHeight;
      setListHeight(height > 420 ? "420px" : `${height}px`);
    }
  }, [brands]);

  //for search brand
  useEffect(() => {
    if (isSearch && brandDetails) {
      setBrands(brandDetails);
      setNoItems(false);
    }
  }, [isSearch, brandDetails]);

  // Toggle the checked state of the clicked checkbox and uncheck all others
  const handleCheckboxChange = (
    index: number,
    brandId: number,
    brandName: string
  ) => {
    if (!activeCategories[index]) {
      setActiveCategories({ [index]: true });
      if (brandId) {
        if (isSearch) {
          window.scrollTo(0, 0);
          if (onSelectedBrandId) {
            onSelectedBrandId(brandName);
          }
        } else {
          window.scrollTo(0, 0);
          navigate(`/shoppingCartItem/${companyId}/${categoryId}/${brandId}`);
        }
      }
    } else {
      setActiveCategories({});
      if (isSearch) {
        window.scrollTo(0, 0);
        if (onSelectedBrandId) {
          onSelectedBrandId("");
        }
      } else {
        window.scrollTo(0, 0);
        navigate(`/shoppingCartItem/${companyId}/${categoryId}`);
      }
    }
  };

  // Fetch brands from the server based on companyId and categoryId
  useEffect(() => {
    if (isSearch !== true && categoryId !== undefined) {
      setActiveCategories({});
      axios
        .get(`${baseUrl}/webBrand/${companyId}/${categoryId}`)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setBrands(response.data.data.brands);
            setNoItems(response.data.data.brands.length === 0);
          } else {
            console.error("No brands found in response");
            setNoItems(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setNoItems(true);
        });
    }
  }, [companyId, categoryId, isSearch]);

  // window.onpopstate = function(event) {
  //     window.location.href = `/shoppingCartHome/${companyId}`;
  // };

  return (
    <div className="pt-6">
      <div className="overflow-hidden rounded-2xl shadow-md w-full md:w-52">
        {noItems ? (
          <div className="text-center mt-5 mb-5">No brands available.</div>
        ) : (
          <ul
            ref={listRef}
            className="bg-white p-2 w-full overflow-y-auto scrollbar-thin scrollbar-thumb-blue-700 scrollbar-track-white"
            style={{ maxHeight: listHeight }}
          >
            {brands.map((brand: any, index) => (
              <li key={index} className="py-1 flex items-center">
                <button
                  type="button"
                  className="flex items-center p-2 hover:bg-blue-100 rounded-md w-full text-left"
                  onClick={() =>
                    handleCheckboxChange(index, brand.brandId, brand.name)
                  }
                >
                  <input
                    type="checkbox"
                    checked={!!activeCategories[index]}
                    onChange={() =>
                      handleCheckboxChange(index, brand.brandId, brand.name)
                    }
                    className="mr-2"
                  />
                  <span className="ml-2 text-sm">{brand.name}</span>
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default FilterBrandBar;
