import React, { useEffect, useState } from 'react';
import './App.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import DefaultLayout from './view/common/DefaultLayout/DefaultLayout';
import SignOut from './view/pages/SignUp/SignUp';
import Loading from './view/common/loader/loading';
import privateAxios from './axiosInstance';
import axios from "axios";

function App() {
    const [loading, setLoading] = useState(false);

    // Request and Response interceptors

    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use(
            (config) => {
                setLoading(true);
                return config;
            },
            (error) => {
                setLoading(false);
                return Promise.reject(error);
            }
        );

        const responseInterceptor = axios.interceptors.response.use(
            (response) => {
                setLoading(false);
                if (response.data.status != 200) {
                    sessionStorage.clear();
                    window.location.href = '/';
                }
                return response;
            },
            (error) => {
                setLoading(false);
                sessionStorage.clear();
                window.location.href = '/';
                return Promise.reject(error);
            }
        );

        return () => {
            privateAxios.interceptors.request.eject(requestInterceptor);
            privateAxios.interceptors.response.eject(responseInterceptor);
        };
    }, []);

    return (
        <HashRouter>
            <Loading show={loading} />
            <Routes>
                <Route path="/*" element={<DefaultLayout />} />
                <Route path="/signUp" element={<SignOut />} />
            </Routes>
        </HashRouter>
    );
}

export default App;
