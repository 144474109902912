import React from 'react';

interface ComboBoxOption {
  value: string;
  label: string;
}

interface ComboBoxProps {
  name: string;
  label: string;
  value: string;
  options: ComboBoxOption[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  isValid: boolean;
  errorMessage: string;
  disabled?: boolean;
}

const ComboBox: React.FC<ComboBoxProps> = ({ name, label, value, options, onChange, isValid, errorMessage, disabled = false }) => {
  return (
    <div className='mb-0 w-full mx-1'>
      <label className="block text-black text-[15px] font-[500] mb-0.5">{label}</label>
      <select
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={`w-full h-[40px] font-Poppins rounded-[5px] px-3 block border-2 ${isValid ? 'border-gray-400' : 'border-red-500'} outline-none focus:border-black`}
      >
        <option value="" disabled>
          Select {label}
        </option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {!isValid && <p className="text-red-500 text-xs">{errorMessage}</p>}
    </div>
  );
};

export default ComboBox;
