import React, { useState } from 'react';
import ForgotOtpForm from './ForgotOtpForm';
import ConfirmPasswordPage from './ConfirmPasswordPage';

const ForgotPasswordPage: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string | null>(null);
    const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);
    const [receivedOtp, setReceivedOtp] = useState<string | null>(null);
    const [showOTPForm, setShowOTPForm] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [userId, setUserId] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false); // New loading state

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setMessage('');
        setLoading(true);


        try {
            const response = await fetch(`${baseUrl}/web-user-forgot`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                setMessageType('error');
                setMessage(`Error: ${errorData.message || 'Something went wrong'}`);
                return;
            }

            const data = await response.json();
            if (data && data.data) {
                const { otp, user } = data.data;
                if (otp && user) {
                    setMessageType('success');
                    setMessage('A reset link has been sent to your email! Please check your inbox for the OTP.');
                    setReceivedOtp(otp);
                    setUserId(user.id);
                    setShowOTPForm(true);
                } else {
                    setMessageType('error');
                    setMessage('Unable to retrieve OTP or user information.');
                }
            } else {
                setMessageType('error');
                setMessage(data.message || 'No data returned. Please try again.');
            }

        } catch (error) {
            setMessageType('error');
            setMessage('Failed to send the reset link. Please try again later.');
        }finally {
        setLoading(false);
    }
    };

    const handleOtpVerify = (otp: string) => {
        if (otp == receivedOtp) {
            setMessageType('success');
            setMessage('OTP verified! You can now set a new password.');
            setShowOTPForm(false);
            setShowConfirmPassword(true);
        } else {
            setMessageType('error');
            setMessage('Invalid OTP. Please try again.');
        }
    };

    const handlePasswordChange = async (newPassword: string) => {
        try {
            const response = await fetch(`${baseUrl}/web-user-forgot/change-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: userId,
                    newPassword: newPassword,
                }),
            });

            if (response.ok) {
                setMessageType('success');
                setMessage('Password has been changed successfully!');
                onClose();
            } else {
                const errorData = await response.json();
                setMessageType('error');
                setMessage(`Error: ${errorData.message}`);
            }
        } catch (error) {
            setMessageType('error');
            setMessage('Failed to change the password. Please try again later.');
        }
    };
    return (
        <div>
            {!showOTPForm && !showConfirmPassword ? (
                <>
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">E-Mail</label>
                            <input
                                type="email"
                                id="email"
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-blue-600 transition duration-300 ease-in-out"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>

                        {message && (
                            <div className={`text-sm ${messageType === 'success' ? 'text-green-500' : 'text-red-500'}`}>
                                {message}
                            </div>
                        )}
                        <button
                            type="submit"
                            className={`w-full bg-blue-600 text-white py-2 px-4 mt-5 rounded hover:bg-blue-700 transition duration-300 ease-in-out ${
                                loading ? 'cursor-not-allowed opacity-50' : ''
                            }`}
                            disabled={loading}
                        >
                            {loading ? 'Sending...' : 'Send Reset Link'}
                        </button>
                        <button onClick={onClose} className="text-blue-600 hover:underline mt-2">
                            Back to Login
                        </button>
                    </form>
                </>
            ) : showOTPForm ? (
                <ForgotOtpForm onVerify={handleOtpVerify} onClose={onClose} message={message}/>
            ) : (
                <ConfirmPasswordPage onClose={onClose} onPasswordChange={handlePasswordChange}/>
            )}
        </div>
    );
};

export default ForgotPasswordPage;
