import React, { useEffect, useState } from 'react';
import CategoryNavBar from './CategoryNavBar';
import SideBar from './SideBar';

import UploadedImage from '../../../Assets/Customer/keren_70.jpg';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import axiosInstance from '../../../axiosInstance';
import { Helmet } from 'react-helmet';

const baseUrl = process.env.REACT_APP_BASE_URL;

interface OrderItem {
  qty: number;
  itemName: string;
  brandName: string;
  categoryName: string;
  imgUrl: string;
  itemId: number;
}

interface OrderInfo {
  orderNo: string;
  fullName: string | null;
  address: string | null;
  city: string | null;
  district: string | null;
  postalCode: string | null;
  mobileNumber: string | null;
  orderItem: OrderItem;
}

interface ItemReviewImg {
  id: number;
  imgUrl: string;
}

interface Reviews {
  id: number;
  rating: number;
  review: string;
  isAnonymous: boolean;
  itemId: number;
  webUserFirstName: string;
  webUserLastName: string;
  webUserUsername: string;
  itemReviewImg: ItemReviewImg[];
  active: boolean;
}


const ReviewForm: React.FC = () => {
  const [productRating, setProductRating] = useState<number | null>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<string>('');

  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [isCheckboxClicked, setIsCheckboxClicked] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [orderInfo, setOrderInfo] = useState<OrderInfo | null>(null);

  const [review, setReviews] = useState<Reviews>();
  const [isUpdatable, setIsUpdatable] = useState<boolean>(false);


  const setStoreUser = (): { userId: string | null, token: string | null } => {
    const storeUser = sessionStorage.getItem("user");
    if (storeUser) {
      const user = JSON.parse(storeUser);
      const userId = user.id;
      const token = user.token;
      return { userId, token };
    }
    return { userId: null, token: null };
  };

  const { userId, token } = setStoreUser();

  const { orderId, itemId, companyId } = useParams();
  console.log("orderId :", orderId);
  console.log("itemId: ", itemId);
  console.log("UserId: ", userId);
  console.log("company id :", companyId);



  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
    setSidebarVisible(!isSidebarVisible);
  };

  const handleRating = (rating: number, setRating: React.Dispatch<React.SetStateAction<number | null>>) => {
    setRating(rating);
  };

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(event.target.value);
  };


  //checkbox
  const handleCheckboxChange = () => {
    setIsCheckboxClicked(!isCheckboxClicked);
    setIsAnonymous(!isCheckboxClicked);
  };

  //file chose
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const filesArray = Array.from(event.target.files);
      if (filesArray.length + selectedFiles.length <= 2) {
        setSelectedFiles([...selectedFiles, ...filesArray]);
      } else {
        setErrorMessage('You can only upload up to 2 images.');
      }
    }
  };

  useEffect(() => {
    const fetchOrderInfo = async () => {
      try {
        const response = await axiosInstance.get(
          `/review/getOrderInfo/${userId}/${orderId}/${itemId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOrderInfo(response.data.data.orderInfo);
      } catch (error) {
        console.error('Error fetching order info:', error);
      }
    };

    if (userId && orderId && itemId) {
      fetchOrderInfo();
    }
  }, [userId, orderId, itemId, token]);

  // get review info
  useEffect(() => {
    const fetchReviewInfo = async () => {
      try {
        const response = await axiosInstance.get(`/review/getItem/${userId}/${itemId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setReviews(response.data.data.review);
        console.log("set reviews :", response.data.data.review);

        if (response.data.data.review) {
          setProductRating(response.data.data.review.rating);
          setFeedback(response.data.data.review.review);
          setIsUpdatable(true);
          console.log("rating : ", response.data.data.review.rating)
        } else {
          setIsUpdatable(false);
        }

      } catch (error) {
        console.error('Error fetching review info:', error);
      }
    };
    if (userId && itemId) {
      fetchReviewInfo();
    }
  }, [userId, itemId, token]);


  // review data post
  const handleSubmitFeedback = async () => {
    if (!productRating || !feedback) {
      setErrorMessage('Please provide a rating and feedback.');
      return;
    }

    let reviewData = {
      // id: null, 
      // rating: productRating,
      // review: feedback,
      // isAnonymous: isAnonymous,
      // itemId: orderInfo?.orderItem.itemId,
      // itemReviewImg: selectedFiles.map((file) => ({
      //   id: null, 
      //   imgUrl: URL.createObjectURL(file),
      // })),
      // active: true,
    };

    if (isUpdatable) {
      reviewData = {
        id: review?.id,
        rating: productRating,
        review: feedback,
        isAnonymous: isAnonymous,
        itemId: itemId,
        itemReviewImg: selectedFiles.map((file) => ({
          id: null,
          imgUrl: URL.createObjectURL(file),
        })),
        active: true,
      }
    } else {
      reviewData = {
        id: null,
        rating: productRating,
        review: feedback,
        isAnonymous: isAnonymous,
        itemId: orderInfo?.orderItem.itemId,
        itemReviewImg: selectedFiles.map((file) => ({
          id: null,
          imgUrl: URL.createObjectURL(file),
        })),
        active: true,
      };
    }

    try {
      const response = await axiosInstance.post(
        `/review`,
        reviewData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire({
        title: 'Success',
        text: 'Review Added successfully!',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      console.log('Review submitted successfully:', response.data);
    } catch (error) {
      console.error('Error submitting review:', error);
      Swal.fire('Error', 'An error occurred. Please try again.', 'error');
    }
  };

  const id = review?.id;
  //inCompleted
  const handleCancelReview = async () => {


    if (id) {
      console.log('Review id get successfully:', id);
      try {
        const response = await axiosInstance.post(
          `/review/disable/${id}`,
          id,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log('Review deleted successfully:', response.data);
        Swal.fire({
          title: 'Success',
          text: 'Review deleted successfully!',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } catch (error) {
        console.error('Error submitting review:', error);
        Swal.fire('Error', 'An error occurred. Please try again.', 'error');
      }
    }
  }


  if (!orderInfo) {
    return <div>Loading...</div>;
  }

  console.log("order Info: ", orderInfo);
  console.log("review info: ", review)
  console.log("review id: ", review?.id)


  return (
    <>
      {/* <Helmet>
        <title>Pet Medi Web - Review Page</title>
        <meta name="description" content="Learn more about Pet Medi, your trusted pet health partner." />
        <meta
          name="keywords"
          content="pet, health, care, dogs, cats, veterinary,Pet clinic in Sri Lanka,Veterinary services Sri Lanka,Pet hospital,Affordable pet care Sri Lanka, 
      Veterinarian near me,Animal clinic Sri Lanka,Pet surgery ,Pet vaccinations Sri Lanka,24/7 emergency vet Sri Lanka, Pet health checkup Sri Lanka, Dog clinic Sri Lanka, 
      Cat veterinary care Sri Lanka, Exotic pet care Sri Lanka, Pet dental care Sri Lanka, Pet grooming, Pet boarding services Sri Lanka, Best vet for pet Sri Lanka, 
      Cataract surgery for pets Sri Lanka, Animal rescue services Sri Lanka ,Veterinary clinic , Sri Lanka , Pet Medi, pet, P , Sri Lanka Pets, Pet Care, Animal Clinic, 
      Vet, Pet Health, Pet Health Checkup, veterinary,Pet dental care, Grooming, Best Animal Clinic, Pet health monitoring app, Veterinary telemedicine Sri Lanka,Pet prescription management,
      Pet weight management Sri Lanka,Pet nutrition advice Sri Lanka,Digital veterinary solutions,Pet clinic appointment system,Senior pet care services,Specialized pet care Sri Lanka,
      Online vet consultation Sri Lanka,Pet illness prevention,Remote vet consultations,Pet healthcare software,Preventive vet care Sri Lanka,Pet emergency hotline Sri Lanka,
      Advanced pet diagnostics,Pet care plans Sri Lanka,In-home veterinary services,Pet surgery aftercare Sri Lanka,Pet grooming and spa Sri Lanka,Veterinary lab testing Sri Lanka,
      Personalized pet healthcare plans,Vet treatment packages Sri Lanka,Animal health solutions Sri Lanka,Vet treatment plans Sri Lanka,Pet physiotherapy Sri Lanka, 
      Vets near me, cat clinic near me, dog clinic near me, rabbit consultant doctors, dog consultant doctors, cat consultant doctors, online pet app, pet app online, 
      app for pets, app for animals, animal doctor, pet doctor, pet consultant, Pet wellness programs Sri Lanka, Pet wellness programs, Veterinary nutrition consultation, 
      Pet behavior training Sri Lanka, Pet behavior training, Veterinary dermatology Sri Lanka, Veterinary dermatology, Puppy vaccinations, Puppy vaccinations Sri Lanka, 
      Kitten vaccinations Sri Lanka, Kitten vaccinations, Flea and tick prevention Sri Lanka, Flea and tick prevention, Microchipping services Sri Lanka, Pet allergy treatment Sri Lanka, Pet allergy treatment,
      Veterinary orthopedic surgery Sri Lanka,Veterinary orthopedic surgery, 24-hour vet service Sri Lanka, 24-hour vet service , Emergency pet care Sri Lanka, Emergency pet care,
      Animal eye care Sri Lanka,Animal eye care,Pet dental cleaning Sri Lanka,Pet dental cleaning,Dog vaccinations Sri Lanka, Dog vaccinations,Cat vaccinations Sri Lanka,Cat vaccinations,Animal diagnostic imaging,
      Vet appointment booking online Sri Lanka,Vet appointment booking online,Pet Surgery Sri Lanka, Pet Surgery,Specialist vet services Sri Lanka,Specialist vet services,Pet cardiologist Sri Lanka, Pet cardiologist,
      Best dog vet Sri Lanka,Best dog vet, Best cat vet Sri Lanka, Best cat vet,Pet health checkup Sri Lanka, Pet health checkup, Puppy health checkup Sri Lanka,Puppy health checkup, Kitten health checkup Sri Lanka,Kitten health checkup,
      Emergency pet clinic near me,Pet health and wellness Sri Lanka,Pet health and wellness , pet treatment,Pet Health, Pet Services,Pet Services near me,Pet health diagnosis,Veterinary diagnosis,Common pet diseases,Animal health conditions,
      Dog illness diagnosis,Cat health problem, Dog health problem, Pet health problem, Pet disease symptoms, Veterinary medical conditions, Signs of illness in pets, Pet diagnostic tests,
      Dog allergy diagnosis, Cat urinary tract infection symptoms, Dog urinary tract infection symptoms, Pet urinary tract infection symptoms, Veterinary blood tests for pets, Pet arthritis treatment,
      Dog diabetes diagnosis, Cat diabetes diagnosis, Pet diabetes diagnosis, Pet cancer signs, Common pet skin diseases, Animal ear infections, Dog digestive problems diagnosis,
      Cat digestive problems diagnosis, Pet digestive problems diagnosis, Pet liver disease symptoms, Kidney disease in cats, Kidney disease in dogs, Kidney disease in pets,
      Pet obesity treatment, Dog heart disease symptoms, Cat heart disease symptoms, Pet heart disease symptoms, Cat thyroid disease, Dog thyroid disease, Pet thyroid disease,
      Pet parasite infections, Veterinary checkup for pets, Dog hip dysplasia diagnosis, Cat respiratory infections, Dog respiratory infections, Pet respiratory infections, Pancreatitis in pet symptoms, Dog Food, Cat Food"
    />
      </Helmet> */}

      <div>
        <CategoryNavBar isDropdownOpen={isDropdownOpen} handleDropdownToggle={handleDropdownToggle} />
        <div className="px-6 sm:px-12 lg:px-16 bg-gray-100 pb-5">
          <div className="flex flex-col md:flex-row gap-4 md:gap-8">
            {/* {isSidebarVisible && (
            <div className="md:sticky top-0">
              <SideBar height="420px" />
            </div>
          )} */}

            <div className="w-full mt-6">
              <div className="max-w-3xl w-full mx-auto p-6 bg-white shadow-md rounded-md">
                <h2 className="text-xl font-semibold mb-4 text-blue-600 text-center md:text-start">Order Information</h2>
                <div className="flex flex-col md:flex-row w-full">
                  <div className="flex w-full md:w-1/3 mb-2 md:mb-0">
                    <p className="text-xs sm:text-sm md:text-sm text-start text-gray-500">Order No: {orderInfo.orderNo}</p>
                  </div>
                  <div className="flex w-full md:w-1/3 mb-2 md:mb-0">
                    <p className="text-xs sm:text-sm md:text-sm text-center text-gray-500">Order Closed: 05 Aug 2024 01:35</p>
                  </div>
                  <div className="flex w-full md:w-1/3">
                    <p className="text-xs sm:text-sm md:text-sm text-end text-gray-500">Seller: Shop1103752433 Store</p>
                  </div>
                </div>

              </div>

              <div className="max-w-3xl w-full mx-auto p-6 bg-white shadow-md rounded-md mt-6">
                <h2 className="text-xl font-semibold mb-4 text-blue-600 text-center md:text-start">Leave feedback about this order</h2>
                <div className="flex flex-col md:flex-row items-center md:items-center">
                  <img
                    src={orderInfo.orderItem.imgUrl}
                    alt="Product"
                    className="w-20 h-20 sm:w-16 sm:h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-md mr-0 md:mr-4 mb-2 md:mb-0"
                  />
                  <div className="text-center md:text-left">
                    <p className="text-sm sm:text-base font-medium text-gray-700">{orderInfo.orderItem.itemName}</p>
                    <p className="text-xs sm:text-sm text-gray-500">{orderInfo.orderItem.brandName}</p>
                    <p className="text-xs sm:text-sm text-gray-500">{orderInfo.orderItem.categoryName}</p>
                    <p className="text-xs sm:text-sm text-gray-500">{orderInfo.orderItem.qty}</p>
                  </div>


                  {/* <div className="mt-4 md:mt-0 md:ml-auto">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Upload Photo</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="block w-full text-sm text-gray-500
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-md file:border-0
                      file:text-sm file:font-semibold
                      file:bg-blue-50 file:text-blue-700
                      hover:file:bg-blue-100"
                  />
                  {selectedFile && <p className="mt-2 text-xs text-gray-500">{selectedFile.name}</p>}
                </div> */}


                </div>

                <div className="grid grid-cols-1 gap-4 mt-4">
                  <div className="flex flex-col">
                    <label className="mb-2 text-sm font-medium text-gray-700">How accurate was the product description?</label>
                    <div className="flex space-x-2">
                      {[1, 2, 3, 4, 5].map(star => (
                        <i
                          key={star}
                          className={`bi bi-star${productRating && productRating >= star ? '-fill' : ''}  text-yellow-500 cursor-pointer hover:text-yellow-500`}
                          onClick={() => handleRating(star, setProductRating)}
                        />
                      ))}
                    </div>

                    {/* feedback and file chooser */}
                    {productRating && (
                      <>
                        <label className="mt-4 mb-2 text-sm font-medium text-gray-700">Your Feedback</label>
                        <textarea
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                          placeholder="Write your feedback here..."
                          value={feedback}
                          onChange={handleFeedbackChange}
                          rows={4}
                        />

                        <label className="mt-4 mb-2 text-sm font-medium text-gray-700">Upload Photo</label>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                          className="block w-full text-sm text-gray-500
                          file:mr-4 file:py-2 file:px-4
                          file:rounded-md file:border-0
                          file:text-sm file:font-semibold
                          file:bg-blue-50 file:text-blue-700
                          hover:file:bg-blue-100
                        "
                        />
                      </>
                    )}



                    <label className="flex items-center mt-4 text-sm font-medium text-gray-700">
                      <input type="checkbox" className="mr-2" checked={isCheckboxClicked} onChange={handleCheckboxChange} /> Leave Feedback as Anonymous
                    </label>
                  </div>

                  {errorMessage && (
                    <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
                  )}

                  <div className="flex items-center justify-end mt-6">
                    <button className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 mr-2 text-sm" onClick={handleCancelReview}>
                      Cancel
                    </button>
                    <button className="border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white px-2 py-2 md:px-4 md:py-2 rounded-md text-sm"
                      onClick={handleSubmitFeedback}
                      disabled={!isCheckboxClicked}
                    >
                      Leave Feedback
                    </button>
                  </div>

                  <p className="text-xs sm:text-sm text-gray-500 text-center mt-4">
                    After you submit your feedback, it can't be revised. However, you're always welcome to leave additional feedback!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ReviewForm;
