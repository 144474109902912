import React from 'react';
// @ts-ignore
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const brands = [
  {
    id: 1,
    img: 'https://img.freepik.com/free-psd/colorful-unicorn-business-logo-psd-template-neon-text-effect-style_53876-114524.jpg?ga=GA1.1.1473019030.1714676778&semt=sph',
    name: 'Brand 1',
  },
  {
    id: 2,
    img: 'https://img.freepik.com/free-psd/gold-foil-logo-mockup_160403-97.jpg?ga=GA1.1.1473019030.1714676778&semt=sph',
    name: 'Brand 2',
  },
  {
    id: 3,
    img: 'https://img.freepik.com/free-psd/3d-logo-mockup_145275-141.jpg?ga=GA1.1.1473019030.1714676778&semt=sph',
    name: 'Brand 3',
  },
  {
    id: 4,
    img: 'https://img.freepik.com/free-psd/business-card-mockup_58466-11385.jpg?ga=GA1.1.1473019030.1714676778&semt=sph',
    name: 'Brand 4',
  },
  {
    id: 5,
    img: 'https://img.freepik.com/free-vector/logo-branding-identity-corporate-vector-logo-design_460848-17153.jpg?ga=GA1.1.1473019030.1714676778&semt=sph',
    name: 'Brand 5',
  },
];

const BrandsSlider = () => {
  const settings = {
    infinite: true,
    arrows: false,
    speed: 500,
    dots: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {breakpoint: 1024,settings: {slidesToShow: 3,slidesToScroll: 1,},},
      {breakpoint: 768,settings: {slidesToShow: 2,slidesToScroll: 1,},},
      {breakpoint: 480,settings: {slidesToShow: 1,slidesToScroll: 1,},},
    ],
  };

  return (
    <div className="container mx-auto py-10 ">
      <h2 className="text-2xl font-semibold text-center mb-6">Our Brands</h2>
      <Slider {...settings}>
        {brands.map((brand) => (
          <div key={brand.id} className="p-4">
            <div className="flex justify-center items-center">
              <img
                src={brand.img}
                alt={brand.name}
                className="rounded-full border border-gray-200 shadow-md"
                style={{ width: '150px', height: '150px' }}
              />
            </div>
            <div className="text-center mt-4">{brand.name}</div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BrandsSlider;
