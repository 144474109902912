import { Box, Button, Modal, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, TextField } from "@mui/material";
import React, { useState } from "react";

interface PopupProps {
  open: boolean;
  handleClose: () => void;
  onAdd: (count: number) => void;
}

const Branches: React.FC<PopupProps> = ({ open, handleClose, onAdd }) => {
  const [input, setInput] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Check if the value is a valid number or empty
    if (/^\d*$/.test(value)) {
      setInput(value);
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleSave = () => {
    if (!error) {
      const count = input === "0" || input === "1" || input === "" ? 1 : parseInt(input, 10); // If 0 or 1, treat as 1 branch
      onAdd(count); // Emit the event with the input value
      console.log("Count :", count);
      handleClose(); // Close the popup
    }
  };

  const isSingleBranch = input === "0" || input === "1";

  return (
    <div className="">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="popup-title"
        aria-describedby="popup-description"
      >
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-4 rounded-md shadow-lg w-1/3">
          <h2 className="text-[15px] md:text-lg lg:text-xl text-center mt-3">
            Multiple Branch Count
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 mb-2">
            <div className="flex flex-col py-3">
              {/* Conditionally render the radio button if input is 0 or 1 */}
              
                <div className="form-floating mb-3">
                  <input 
                    type="number" 
                    id="numeric-input" 
                    placeholder='Enter Branches Count'
                    value={input} 
                    onChange={handleChange} 
                    className="form-control mt-1 block w-full px-3 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                    required
                  />
                </div>
             

              <Button
                variant="contained"
                onClick={handleSave}
                sx={{ mt: 2 }}
                disabled={error}
              >
                Add
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Branches;
