import React, { useState } from 'react';

const FilterPriceBar = () => {
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);

    const handleApply = () => {
        console.log(`Min Price: ${minPrice}, Max Price: ${maxPrice}`);
    };

    return (
        <div className="pt-6">
            <div className="overflow-hidden rounded-2xl shadow-md w-full md:w-52 p-4 bg-white">
                <div className="mb-4">
                    <label className="block text-gray-700 text-xm font-bold">Min Price</label>
                    <input
                        type="number"
                        min={0}
                        placeholder='0.00'
                        onChange={(e) => setMinPrice(parseFloat(e.target.value))}
                        className="w-full px-3 py-2 border rounded-lg"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-xm font-bold">Max Price</label>
                    <input
                        type="number"
                        min={0}
                        placeholder='0.00'
                        onChange={(e) => setMaxPrice(parseFloat(e.target.value))}
                        className="w-full px-3 py-2 border rounded-lg"
                    />
                </div>
                <button
                    onClick={handleApply}
                    className="w-full bg-blue-500 text-white px-3 py-2 rounded-lg hover:bg-blue-700"
                >
                    Apply
                </button>
            </div>
        </div>
    );
};

export default FilterPriceBar;
