import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./privacyPolicy.css";
export default class PrivacyPolicy extends Component {
  render() {
    return (
      <>
        {/* <Helmet>
          <title>Pet Medi Web - Privacy-Policy</title>
          <meta
            name="description"
            content="Learn more about Pet Medi, your trusted pet health partner."
          />
          <meta
            name="keywords"
            content="pet, health, care, dogs, cats, veterinary,Pet clinic in Sri Lanka,Veterinary services Sri Lanka,Pet hospital,Affordable pet care Sri Lanka, 
      Veterinarian near me,Animal clinic Sri Lanka,Pet surgery ,Pet vaccinations Sri Lanka,24/7 emergency vet Sri Lanka, Pet health checkup Sri Lanka, Dog clinic Sri Lanka, 
      Cat veterinary care Sri Lanka, Exotic pet care Sri Lanka, Pet dental care Sri Lanka, Pet grooming, Pet boarding services Sri Lanka, Best vet for pet Sri Lanka, 
      Cataract surgery for pets Sri Lanka, Animal rescue services Sri Lanka ,Veterinary clinic , Sri Lanka , Pet Medi, pet, P , Sri Lanka Pets, Pet Care, Animal Clinic, 
      Vet, Pet Health, Pet Health Checkup, veterinary,Pet dental care, Grooming, Best Animal Clinic, Pet health monitoring app, Veterinary telemedicine Sri Lanka,Pet prescription management,
      Pet weight management Sri Lanka,Pet nutrition advice Sri Lanka,Digital veterinary solutions,Pet clinic appointment system,Senior pet care services,Specialized pet care Sri Lanka,
      Online vet consultation Sri Lanka,Pet illness prevention,Remote vet consultations,Pet healthcare software,Preventive vet care Sri Lanka,Pet emergency hotline Sri Lanka,
      Advanced pet diagnostics,Pet care plans Sri Lanka,In-home veterinary services,Pet surgery aftercare Sri Lanka,Pet grooming and spa Sri Lanka,Veterinary lab testing Sri Lanka,
      Personalized pet healthcare plans,Vet treatment packages Sri Lanka,Animal health solutions Sri Lanka,Vet treatment plans Sri Lanka,Pet physiotherapy Sri Lanka, 
      Vets near me, cat clinic near me, dog clinic near me, rabbit consultant doctors, dog consultant doctors, cat consultant doctors, online pet app, pet app online, 
      app for pets, app for animals, animal doctor, pet doctor, pet consultant, Pet wellness programs Sri Lanka, Pet wellness programs, Veterinary nutrition consultation, 
      Pet behavior training Sri Lanka, Pet behavior training, Veterinary dermatology Sri Lanka, Veterinary dermatology, Puppy vaccinations, Puppy vaccinations Sri Lanka, 
      Kitten vaccinations Sri Lanka, Kitten vaccinations, Flea and tick prevention Sri Lanka, Flea and tick prevention, Microchipping services Sri Lanka, Pet allergy treatment Sri Lanka, Pet allergy treatment,
      Veterinary orthopedic surgery Sri Lanka,Veterinary orthopedic surgery, 24-hour vet service Sri Lanka, 24-hour vet service , Emergency pet care Sri Lanka, Emergency pet care,
      Animal eye care Sri Lanka,Animal eye care,Pet dental cleaning Sri Lanka,Pet dental cleaning,Dog vaccinations Sri Lanka, Dog vaccinations,Cat vaccinations Sri Lanka,Cat vaccinations,Animal diagnostic imaging,
      Vet appointment booking online Sri Lanka,Vet appointment booking online,Pet Surgery Sri Lanka, Pet Surgery,Specialist vet services Sri Lanka,Specialist vet services,Pet cardiologist Sri Lanka, Pet cardiologist,
      Best dog vet Sri Lanka,Best dog vet, Best cat vet Sri Lanka, Best cat vet,Pet health checkup Sri Lanka, Pet health checkup, Puppy health checkup Sri Lanka,Puppy health checkup, Kitten health checkup Sri Lanka,Kitten health checkup,
      Emergency pet clinic near me,Pet health and wellness Sri Lanka,Pet health and wellness , pet treatment,Pet Health, Pet Services,Pet Services near me,Pet health diagnosis,Veterinary diagnosis,Common pet diseases,Animal health conditions,
      Dog illness diagnosis,Cat health problem, Dog health problem, Pet health problem, Pet disease symptoms, Veterinary medical conditions, Signs of illness in pets, Pet diagnostic tests,
      Dog allergy diagnosis, Cat urinary tract infection symptoms, Dog urinary tract infection symptoms, Pet urinary tract infection symptoms, Veterinary blood tests for pets, Pet arthritis treatment,
      Dog diabetes diagnosis, Cat diabetes diagnosis, Pet diabetes diagnosis, Pet cancer signs, Common pet skin diseases, Animal ear infections, Dog digestive problems diagnosis,
      Cat digestive problems diagnosis, Pet digestive problems diagnosis, Pet liver disease symptoms, Kidney disease in cats, Kidney disease in dogs, Kidney disease in pets,
      Pet obesity treatment, Dog heart disease symptoms, Cat heart disease symptoms, Pet heart disease symptoms, Cat thyroid disease, Dog thyroid disease, Pet thyroid disease,
      Pet parasite infections, Veterinary checkup for pets, Dog hip dysplasia diagnosis, Cat respiratory infections, Dog respiratory infections, Pet respiratory infections, Pancreatitis in pet symptoms, Dog Food, Cat Food"
    />
        </Helmet> */}

        <div className="pt-20 px-6 xl:px-16 md:px-12 lg:px-14 pb-20">
          <h2 className="text-2xl pb-5">Privacy Policy</h2>
          <p className="pb-2 text-xl">
            <strong>Introduction</strong>
          </p>
          <p className="pb-5">
            PetMedi (“we,” “our,” or “us”) is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, disclose,
            and safeguard your information when you use our software as a
            service (SAAS) platform. Please read this Privacy Policy carefully.
            If you do not agree with the terms of this Privacy Policy, please do
            not access the platform.
          </p>

          <p className="pb-2 text-xl">
            <strong>Information We Collect</strong>
          </p>
          <p className="pb-5">
            <strong>Personal Data:</strong> When you register for an account, we
            may collect personal data including but not limited to your name,
            email address, phone number, and payment information.
          </p>
          <p className="pb-5">
            <strong>Usage Data:</strong> We collect information on how you
            access and use the platform. This may include your IP address,
            browser type, pages visited, and other usage data.
          </p>
          <p className="pb-5">
            <strong>Cookies and Tracking Technologies:</strong> We use cookies
            and similar tracking technologies to track activity on our platform
            and store certain information. You can instruct your browser to
            refuse all cookies or indicate when a cookie is being sent.
          </p>

          <p className="pb-2 text-xl">
            <strong>How We Use Your Information</strong>
          </p>
          <p className="p-2">
            We use the collected data for various purposes:{" "}
          </p>
          <ul className="pb-5">
            <li>
              {" "}
              <span>
                <i className="bi bi-check-circle text-blue-600 px-2 text-left text-lg"></i>
              </span>{" "}
              To provide and maintain our platform
            </li>
            <li>
              {" "}
              <span>
                <i className="bi bi-check-circle text-blue-600 px-2 text-left text-lg"></i>
              </span>{" "}
              To notify you about changes to our platform
            </li>
            <li>
              {" "}
              <span>
                <i className="bi bi-check-circle text-blue-600 px-2 text-left text-lg"></i>
              </span>{" "}
              To allow you to participate in interactive features of our
              platform
            </li>
            <li>
              {" "}
              <span>
                <i className="bi bi-check-circle text-blue-600 px-2 text-left text-lg"></i>
              </span>{" "}
              To provide customer support
            </li>
            <li>
              {" "}
              <span>
                <i className="bi bi-check-circle text-blue-600 px-2 text-left text-lg"></i>
              </span>{" "}
              To gather analysis or valuable information so that we can improve
              our platform
            </li>
            <li>
              {" "}
              <span>
                <i className="bi bi-check-circle text-blue-600 px-2 text-left text-lg"></i>
              </span>{" "}
              To monitor the usage of our platform
            </li>
            <li>
              {" "}
              <span>
                <i className="bi bi-check-circle text-blue-600 px-2 text-left text-lg"></i>
              </span>{" "}
              To detect, prevent, and address technical issues
            </li>
          </ul>

          <p className="pb-2 text-xl">
            <strong>Sharing Your Information</strong>
          </p>
          <p className="p-2">
            We may share your personal information in the following situations:
          </p>
          <ul className="pb-5 ">
            <li>
              <span>
                <i className="bi bi-check-circle text-blue-600 px-2 text-left text-lg"></i>
              </span>
              With Service Providers: We may share your personal information
              with service providers to monitor and analyze the use of our
              platform.
            </li>
            <li>
              <span>
                <i className="bi bi-check-circle text-blue-600 px-2 text-left text-lg"></i>
              </span>
              For Business Transfers: We may share or transfer your personal
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
            <li>
              <span>
                <i className="bi bi-check-circle text-blue-600 px-2 text-left text-lg"></i>
              </span>
              With Affiliates: We may share your information with our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy.
            </li>
            <li>
              <span>
                <i className="bi bi-check-circle text-blue-600 px-2 text-left text-lg"></i>
              </span>
              With Business Partners: We may share your information with our
              business partners to offer you certain products, services, or
              promotions.
            </li>
            <li>
              <span>
                <i className="bi bi-check-circle text-blue-600 px-2 text-left text-lg"></i>
              </span>
              With Your Consent: We may disclose your personal information for
              any other purpose with your consent.
            </li>
          </ul>

          <p className="pb-2 text-xl">
            <strong>Security of Your Information</strong>
          </p>
          <p className="pb-5">
            We use administrative, technical, and physical security measures to
            protect your personal information. While we have taken reasonable
            steps to secure the personal information you provide to us, please
            be aware that despite our efforts, no security measures are perfect
            or impenetrable, and no method of data transmission can be
            guaranteed against any interception or other types of misuse.
          </p>

          <p className="pb-2 text-xl">
            <strong>Your Data Protection Rights</strong>
          </p>
          <p className="pb-5">
            Depending on your location, you may have the following rights
            regarding your personal information:
          </p>
          <ul className="pb-5">
            <li>
              <span>
                <i className="bi bi-check-circle text-blue-600 px-2 text-left text-lg"></i>
              </span>
              The right to access - You have the right to request copies of your
              personal data.
            </li>
            <li>
              <span>
                <i className="bi bi-check-circle text-blue-600 px-2 text-left text-lg"></i>
              </span>
              The right to rectification - You have the right to request that we
              correct any information you believe is inaccurate or complete
              information you believe.
            </li>
          </ul>
        </div>
      </>
    );
  }
}
