const emailRegex = /^[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const nameRegex =/^[a-zA-Z\s'-]{2,50}$/;
const addressRegex = /^[a-zA-Z0-9\s,'-.#]{3,100}$/;
const mobileRegex = /^\+?[0-9\s-]{7,15}$/;

export function validateEmail(email: string): boolean {
    return emailRegex.test(email);
}

export function validatePassword(password: string): boolean {
    return passwordRegex.test(password);
}

export function validateName(name: string): boolean {
    return nameRegex.test(name);
}

export function validateAddress(address: string): boolean {
    return addressRegex.test(address);
}

export function validateMobile(mobile: string): boolean {
    return mobileRegex.test(mobile);
}