import React from 'react';

interface CartItem {
  id: number;
  quantity: number;
  cartId: number;
  itemId: number;
  itemName: string;
  imgURL: string;
  companyId: number;
  batchId: number;
  sellingPrice: number;
  isSupported?: boolean;
  deliveryCharge?: number | null;
}

interface CartItemsSectionProps {
  updatedCartItems: CartItem[];
  handleDelete: (itemId: number) => void;
  isVisibleMassage: boolean;
}

const CartItemsSection: React.FC<CartItemsSectionProps> = ({ updatedCartItems, handleDelete ,isVisibleMassage}) => {   
  return (
    <div>
      {updatedCartItems.map((orderItem, index) => (
        <div key={index} className="bg-white p-4 rounded-lg shadow mb-1">
          <div className="flex items-center mb-4">
            <img src={orderItem.imgURL} alt={orderItem.itemName} className="w-20 h-20 rounded mr-4" />
            <div>
              <p className="font-semibold">{orderItem.itemName}</p>
              <p className="text-sm">Qty: {orderItem.quantity}</p>
            </div>
            <div className="ml-auto text-right">
              <p className="font-semibold">Rs. {(orderItem.sellingPrice * orderItem.quantity).toFixed(2)}</p>
            </div>
          </div>
          <div className="border-t pt-4">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center text-green-600">
              {orderItem.isSupported === undefined ? '' : (
                orderItem.isSupported || isVisibleMassage ? (
                  isVisibleMassage ? (
                    <div className="flex">
                      <span className="material-icons mr-3">task_alt</span>
                      PickUp Store
                    </div>
                  ) : (
                    <div className="flex">
                      <span className="material-icons mr-3">task_alt</span>
                      Standard Delivery
                    </div>
                  )
                 
                ) : (
                  <div className="flex text-rose-800">
                    <span className="material-icons mr-3">cancel</span>
                    Unavailable Delivery This Area!
                  </div>
                )
              )}
              </div>
              <div className="relative group">
                <button onClick={() => handleDelete(orderItem.id)} className="ml-4 text-gray-400">
                  <span className="material-icons">delete</span>
                </button>
                <div className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2">
                  Delete
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CartItemsSection;