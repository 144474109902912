import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import UploadedImage from "../../../Assets/Customer/keren_70.jpg";
import UploadedImage2 from "../../../Assets/handshak.jpg";
import LoginModal from "../Items/LoginModal";
import axios from "axios";
import {
  FaThumbsUp,
  FaHeart,
  FaGrinSquintTears,
  FaSadTear,
  FaSmile,
  FaAngry
} from "react-icons/fa";
import {comment} from "postcss";
import {forEach} from "react-bootstrap/ElementChildren";
import { Helmet } from "react-helmet";

const baseUrl = process.env.REACT_APP_BASE_URL;

interface User {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface Reaction {
  id: number;
  reactNo: number;
  commentId: number;
  webUseId:number;
}

interface Comment {
  id: number;
  comment: string;
  children: Comment[];
  timestamp: string;
  reaction: number | null;
  parentCommentId: number | null;
  webUserId: number;
  blogId: number;
  active: boolean;
  user: User;
  reacts: Reaction[];
}


interface Blog {
  id: number;
  title: string;
  content: string;
  author: string;
  position: string;
  modifyDate: string;
  blogImgList: {
    id: number;
    imageUrl: string;
    blogId: null;
  }[];
}

interface React {
  id: number;
  reactNo: number;
  commentId: number;
  webUseId:number;
}


interface CommentReact {
  id: number;
  reactNo: number;
  commentId: number;
  webUseId: number;
}


type Reactions = {
  one: number;
  two: number;
  three: number;
  four: number;
  five: number;
};
export default function BlogView() {
  const [comments, setComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState<string>("");
  const [replyTo, setReplyTo] = useState<number | null>(null);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [loggedInUser, setLoggedInUser] = useState<any>(null);
  const [commentsVisible, setCommentsVisible] = useState<boolean>(false);

  const [blog, setBlog] = useState<Blog | null>(null);
  const [emojiVisible, setEmojiVisible] = useState<number | null>(null);
  const { postId } = useParams();

  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(9);
  const commentSectionRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const commentsRef = useRef<HTMLDivElement | null>(null);
  const [isMore, setIsMore] = useState(false);

  const [selectedEmojis, setSelectedEmojis] = useState<{ [key: number]: number }>({});
  const [react, setReact] = useState<React>();
  const [showReactions, setShowReactions] = useState<{ [key: number]: boolean }>({});
  const [userReactions, setUserReactions] = useState<any>(null); // Adjust the type as needed
  const [reactions, setReactions] = useState<{ [key: number]: Reactions }>({});
  const [commentReact, setCommentReact] = useState<CommentReact | null>(null); // Initialize with null
  const [commentReplyUser, setCommentReplyUser] = useState<string | null>(null); // Initialize with null




  useEffect(() => {
    const user = sessionStorage.getItem("user");
    if (user) {
      setLoggedInUser(JSON.parse(user));
    }
  }, []);



  const getUserReactions = async (commentId: number, webUsrId: number) => {
    if (!loggedInUser) return;
    const { token } = setStoreUser();

    try {
      const response = await axios.get(`${baseUrl}/blog_comment_react/get/${commentId}/${webUsrId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data) {
        const userReactions = response.data.data;
        setCommentReact(userReactions);
        return userReactions;
      }
    } catch (error) {
      console.error('Error fetching user reactions:', error);
    }
  };


  useEffect(() => {
    if (loggedInUser && comments) {
      comments.forEach(comment => {
        fetchAndSetUserReactions(comment.id, loggedInUser.id);
      });
    }
  }, [loggedInUser, comments]);

  const fetchAndSetUserReactions = async (commentId: number, webUsrId: number) => {
    const userReactions = await getUserReactions(commentId, webUsrId);
    setUserReactions(userReactions);
    const reactionNo = userReactions?.react?.reactNo !== undefined
      ? userReactions.react.reactNo
      : null;
    setSelectedEmojis(prevEmojis => ({
      ...prevEmojis,
      [commentId]: reactionNo,
    }));
  };

  const setStoreUser = (): { userId: string | null; token: string | null } => {
    const storeUser = sessionStorage.getItem("user");
    if (storeUser) {
      const user = JSON.parse(storeUser);
      const userId = user.id;
      const token = user.token;
      return { userId, token };
    }
    return { userId: null, token: null };
  };

  const { userId, token } = setStoreUser();

  const fetchAllReactions = async (commentId: number) => {
    try {
      const response = await axios.get(`${baseUrl}/blog_comment_react/getAll/${commentId}`);

      if (response.status === 200 && response.data) {
        const reactionsArray: React[] = response.data.data.react;

        const reactionCounts: Reactions = {
          one: 0,
          two: 0,
          three: 0,
          four: 0,
          five: 0,
        };
        reactionsArray.forEach(reaction => {
          switch (reaction.reactNo) {
            case 1:
              reactionCounts.one++;
              break;
            case 2:
              reactionCounts.two++;
              break;
            case 3:
              reactionCounts.three++;
              break;
            case 4:
              reactionCounts.four++;
              break;
            case 5:
              reactionCounts.five++;
              break;
          }
        });
        setReactions((prev) => ({
          ...prev,
          [commentId]: reactionCounts,
        }));
      }
    } catch (error) {
      console.error('Error fetching blog reactions:', error);
    }
  };



  const countReactions = (blogId: number) => {
    const reaction = reactions[blogId];
    if (!reaction) return 0;
    return (
      (reaction.one || 0) +
      (reaction.two || 0) +
      (reaction.three || 0) +
      (reaction.four || 0) +
      (reaction.five || 0)
    );
  };

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        if (postId) {
          const response = await axios.get(`${baseUrl}/web_blog/${postId}`, {
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200 && response.data.data) {
            setBlog(response.data.data.blog);
          } else {
            console.error("Failed to fetch blog");
          }
        }
      } catch (error) {
        console.error("There was an error fetching the blog!", error);
      }
    };
    fetchBlog();
  }, [postId, token]);


  useEffect(() => {
    const handleScroll = () => {
      if (isMore === true) {
        return;
      }
      if (commentsRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = commentsRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 10 && !isLoading) {
          getComments(blog ? blog?.id : -1);
        }
      }
    };

    const commentSection = commentsRef.current;
    if (commentSection) {
      commentSection.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (commentSection) {
        commentSection.removeEventListener("scroll", handleScroll);
      }
    };
  }, [comments, isLoading]);


  const handleInputClick = () => {
    if (!loggedInUser) {
      setShowLoginModal(true);
    }
  };

  const toggleCommentsVisibility = () => {
    if (loggedInUser && comments) {
      comments.forEach(comment => {
        fetchAndSetUserReactions(comment.id, loggedInUser.id);
      });
    }
    setCommentsVisible(!commentsVisible);
    checkvisibility(!commentsVisible);
  };

  if (!blog) {
    return <div>Blog not found</div>;
  }

  //comment time
  const calculateTimeDifference = (timestamp: string) => {
    const currentTime = new Date();
    const commentTime = new Date(timestamp);
    const diff = currentTime.getTime() - commentTime.getTime();

    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(diff / 3600000);
    const days = Math.floor(diff / 86400000);

    if (minutes < 1) return "now";
    if (minutes < 60) return `${minutes}m`;
    if (hours < 24) return `${hours}h`;
    return `${days}d`;
  };


  const addCommentOrReply = async () => {
    if (newComment.trim()) {
      const requestBody = {
        id: null,
        comment: newComment,
        parentCommentId: replyTo ? replyTo : null,
        webUserId: userId,
        blogId: blog?.id,
        active: true,
      };

      try {
        const response = await axios.post(`${baseUrl}/blog_comment`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          console.log("Comment or reply added successfully");
          getComments(blog?.id);
        }
      } catch (error) {
        console.error("There was an error adding the comment or reply", error);
      }
    }
  };

  const getComments = async (blogId: number) => {
    setIsLoading(true);

    const requestBody = {
      offset: offset,
      limit: 10,
      columnName: "id",
    };

    try {
      const response = await axios.post(
          `${baseUrl}/blog_comment/getComments/${blogId}`,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
      );

      console.log("comment get data:", requestBody);

      if (response.status === 200) {
        const newComments = response.data.data.comments;

        setComments(newComments);
        setOffset((prevOffset) => prevOffset + 1);


        newComments.forEach((comment: Comment) => {
          fetchAndSetUserReactions(comment.id, loggedInUser.id);
          fetchAllReactions(comment.id);

          comment.children.forEach((childComment) => {
            fetchAndSetUserReactions(childComment.id, loggedInUser.id);
            fetchAllReactions(childComment.id);
          });
        });
        if (newComments.length < 10) {
          setIsMore(false);
        } else {
          setIsMore(true);
        }
      }
    } catch (error) {
      console.error("There was an error fetching the comments", error);
    }

    setIsLoading(false);
  };



  const checkvisibility = (commentsVisible: boolean) => {
    if (commentsVisible) {
      getComments(blog ? blog?.id : -1);
    }
  }


  const handleReplyCommentReplyClick = (commentId: number, replyCommentId: number) => {
    console.log(commentId, "handleReplyClick");
    console.log("comments array", comments);
    setReplyTo(commentId);
    if (commentSectionRef.current) {
      commentSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    const selectedComment = comments.find(comment => {
      if (comment.id === commentId) {
        return true;
      }
      return comment.children.some(child => child.id === replyCommentId);
    });

    if (selectedComment) {
      console.log("Selected comment:", selectedComment);

        const childComment = selectedComment.children.find(child => child.id === replyCommentId);
        if (childComment && childComment.user) {
          setCommentReplyUser(`${childComment.user.firstName} ${childComment.user.lastName}`);
        } else {
          console.error("User not found for the selected reply comment");
        }
    } else {
      console.error("Comment with the given ID not found");
    }
  };


  const handleReplyClick = (commentId: number) => {
    console.log(commentId, "handleReplyClick")
    console.log("comments array",comments)

    setReplyTo(commentId);
    if (commentSectionRef.current) {
      commentSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }

    const selectedComment = comments.find(comment => comment.id === commentId);
    if (selectedComment) {
      console.log("Selected comment:", selectedComment);
      if (selectedComment.user) {
        setReplyTo(commentId);
        setCommentReplyUser(`${selectedComment.user.firstName} ${selectedComment.user.lastName}`);
      } else {
        console.error("User not found for the selected comment");
      }
    } else {
      console.error("Comment with the given ID not found");
    }

  };

  
  const toggleEmojiSelector = (commentId: number) => {
    setEmojiVisible((prev) => (prev === commentId ? null : commentId));
  };


  const handleReaction = async (commentId: number, reactNo: number) => {
    if (!loggedInUser) {
      setShowLoginModal(true);
      return;
    }
    const currentReaction = selectedEmojis[commentId];

    if (currentReaction === reactNo) {
      await handleDeleteReaction(commentId);
      return;
    }
    const userReactions = await getUserReactions(commentId, loggedInUser.id);
    const existingReactionId = userReactions?.react?.id !== undefined
      ? userReactions.react.id
      : null;

    const data = {
      id: existingReactionId,
      reactNo: reactNo,
      commentId: commentId,
      webUserId: loggedInUser.id,
    };
    try {
      if (currentReaction && currentReaction === reactNo) {
        setSelectedEmojis((prevReactions) => ({
          ...prevReactions,
          [commentId]: 0,
        }));
      } else {
        const response = await axios.post(`${baseUrl}/blog_comment_react`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          setSelectedEmojis((prevReactions) => ({
            ...prevReactions,
            [commentId]: reactNo,
          }));
          fetchAllReactions(commentId);
        }
      }
    } catch (error) {
      console.error('Failed to send reaction:', error);
    }
  };

  //delete
  const handleDeleteReaction = async (commentId: number) => {
    if (!loggedInUser) {
      setShowLoginModal(true);
      return;
    }

    if (userId) {

      const userReactions = await getUserReactions(commentId, loggedInUser.id);
      const existingReactionId = userReactions?.react?.id !== undefined
        ? userReactions.react.id
        : null;

      const data = {
        id: existingReactionId,
        reactNo: null,
        commentId: commentId,
        webUserId: userId,
      };
      try {
        const response = await axios.post(`${baseUrl}/blog_comment_react/remove`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 200) {
          setSelectedEmojis((prevReactions) => ({
            ...prevReactions,
            [commentId]: 0,
          }));
          fetchAllReactions(commentId);
        }
      } catch (error) {
        console.error("Failed to Delete reaction:", error);
      }
    }
  };

  const handleMouseEnter = (blogId: number) => {
    setShowReactions((prev) => ({
      ...prev,
      [blogId]: true,
    }));
  };

  const handleMouseLeave = (blogId: number) => {
    setShowReactions((prev) => ({
      ...prev,
      [blogId]: false,
    }));
  };

  // Helper function to render the correct emoji based on selection
  const renderSelectedEmoji = (emojiId: number) => {
    const selectedEmoji = selectedEmojis[emojiId];
    switch (selectedEmoji) {
      case 1:
        return <FaThumbsUp className="text-blue-600" />;
      case 2:
        return <FaHeart className="text-red-600" />;
      case 3:
        return <FaSmile className="text-yellow-600" />;
      case 4:
        return <FaAngry className="text-orange-600" />;
      case 5:
        return <FaSadTear className="text-purple-600" />;
      default:
        return <FaThumbsUp className="text-gray-400" />; // Default to thumbs up icon
    }
  };



  return (

    <>
      {/* <Helmet>
        <title>Pet Medi Web - Blog View</title>
        <meta
          name="description"
          content="Learn more about Pet Medi, your trusted pet health partner."
        />
        <meta
          name="keywords"
          content="pet, health, care, dogs, cats, veterinary,Pet clinic in Sri Lanka,Veterinary services Sri Lanka,Pet hospital,Affordable pet care Sri Lanka, 
      Veterinarian near me,Animal clinic Sri Lanka,Pet surgery ,Pet vaccinations Sri Lanka,24/7 emergency vet Sri Lanka, Pet health checkup Sri Lanka, Dog clinic Sri Lanka, 
      Cat veterinary care Sri Lanka, Exotic pet care Sri Lanka, Pet dental care Sri Lanka, Pet grooming, Pet boarding services Sri Lanka, Best vet for pet Sri Lanka, 
      Cataract surgery for pets Sri Lanka, Animal rescue services Sri Lanka ,Veterinary clinic , Sri Lanka , Pet Medi, pet, P , Sri Lanka Pets, Pet Care, Animal Clinic, 
      Vet, Pet Health, Pet Health Checkup, veterinary,Pet dental care, Grooming, Best Animal Clinic, Pet health monitoring app, Veterinary telemedicine Sri Lanka,Pet prescription management,
      Pet weight management Sri Lanka,Pet nutrition advice Sri Lanka,Digital veterinary solutions,Pet clinic appointment system,Senior pet care services,Specialized pet care Sri Lanka,
      Online vet consultation Sri Lanka,Pet illness prevention,Remote vet consultations,Pet healthcare software,Preventive vet care Sri Lanka,Pet emergency hotline Sri Lanka,
      Advanced pet diagnostics,Pet care plans Sri Lanka,In-home veterinary services,Pet surgery aftercare Sri Lanka,Pet grooming and spa Sri Lanka,Veterinary lab testing Sri Lanka,
      Personalized pet healthcare plans,Vet treatment packages Sri Lanka,Animal health solutions Sri Lanka,Vet treatment plans Sri Lanka,Pet physiotherapy Sri Lanka, 
      Vets near me, cat clinic near me, dog clinic near me, rabbit consultant doctors, dog consultant doctors, cat consultant doctors, online pet app, pet app online, 
      app for pets, app for animals, animal doctor, pet doctor, pet consultant, Pet wellness programs Sri Lanka, Pet wellness programs, Veterinary nutrition consultation, 
      Pet behavior training Sri Lanka, Pet behavior training, Veterinary dermatology Sri Lanka, Veterinary dermatology, Puppy vaccinations, Puppy vaccinations Sri Lanka, 
      Kitten vaccinations Sri Lanka, Kitten vaccinations, Flea and tick prevention Sri Lanka, Flea and tick prevention, Microchipping services Sri Lanka, Pet allergy treatment Sri Lanka, Pet allergy treatment,
      Veterinary orthopedic surgery Sri Lanka,Veterinary orthopedic surgery, 24-hour vet service Sri Lanka, 24-hour vet service , Emergency pet care Sri Lanka, Emergency pet care,
      Animal eye care Sri Lanka,Animal eye care,Pet dental cleaning Sri Lanka,Pet dental cleaning,Dog vaccinations Sri Lanka, Dog vaccinations,Cat vaccinations Sri Lanka,Cat vaccinations,Animal diagnostic imaging,
      Vet appointment booking online Sri Lanka,Vet appointment booking online,Pet Surgery Sri Lanka, Pet Surgery,Specialist vet services Sri Lanka,Specialist vet services,Pet cardiologist Sri Lanka, Pet cardiologist,
      Best dog vet Sri Lanka,Best dog vet, Best cat vet Sri Lanka, Best cat vet,Pet health checkup Sri Lanka, Pet health checkup, Puppy health checkup Sri Lanka,Puppy health checkup, Kitten health checkup Sri Lanka,Kitten health checkup,
      Emergency pet clinic near me,Pet health and wellness Sri Lanka,Pet health and wellness , pet treatment,Pet Health, Pet Services,Pet Services near me,Pet health diagnosis,Veterinary diagnosis,Common pet diseases,Animal health conditions,
      Dog illness diagnosis,Cat health problem, Dog health problem, Pet health problem, Pet disease symptoms, Veterinary medical conditions, Signs of illness in pets, Pet diagnostic tests,
      Dog allergy diagnosis, Cat urinary tract infection symptoms, Dog urinary tract infection symptoms, Pet urinary tract infection symptoms, Veterinary blood tests for pets, Pet arthritis treatment,
      Dog diabetes diagnosis, Cat diabetes diagnosis, Pet diabetes diagnosis, Pet cancer signs, Common pet skin diseases, Animal ear infections, Dog digestive problems diagnosis,
      Cat digestive problems diagnosis, Pet digestive problems diagnosis, Pet liver disease symptoms, Kidney disease in cats, Kidney disease in dogs, Kidney disease in pets,
      Pet obesity treatment, Dog heart disease symptoms, Cat heart disease symptoms, Pet heart disease symptoms, Cat thyroid disease, Dog thyroid disease, Pet thyroid disease,
      Pet parasite infections, Veterinary checkup for pets, Dog hip dysplasia diagnosis, Cat respiratory infections, Dog respiratory infections, Pet respiratory infections, Pancreatitis in pet symptoms, Dog Food, Cat Food"
    />
      </Helmet> */}

      <div className="w-auto">
        <div className="relative mb-8 bg-cover bg-center w-full h-screen">
          {blog.blogImgList.length > 0 ? (
            <img
              src={blog.blogImgList[0].imageUrl}
              alt={blog.title}
              className="img-fluid w-full h-screen mb-6 object-cover"
            />
          ) : (
            <img
              src={UploadedImage2}
              alt="Default"
              className="img-fluid w-full h-screen mb-6 object-cover"
            />
          )}

          {/* Title overlay on image */}
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold px-4 py-2 rounded flex-row">
              {blog.title}
            </h1>
            <p className="text-white mb-2 flex-row text-left">
              <time dateTime={blog.modifyDate}>{blog.modifyDate}</time>
            </p>
          </div>

        </div>
        <div className="prose px-6 xl:px-16 md:px-12 lg:px-14">
          {/* <h1 className="text-lg text-center md:text-2xl lg:text-3xl font-bold mb-4">{blog.title}</h1> */}
          {/* <p className="text-gray-600 mb-2">
          <time dateTime={blog.modifyDate}>{blog.modifyDate}</time>
        </p> */}
          {/* <p className="text-blue-600 mb-2">
          Category: <span className="text-black">{blog.title}</span>
        </p> */}
          <p className="mb-1">
            <span className="text-blue-600 font-semibold">{blog.author}</span>
          </p>
          <p className="mb-2">
            <span className="text-black text-[12px] font-medium">
              {blog.position}
            </span>
          </p>
          <div
            className="mt-5 prose lg:prose-lg text-gray-600"
            dangerouslySetInnerHTML={{ __html: blog.content }}
          ></div>

          {/* Comments Section */}
          <div className="comment-section mt-8 w-auto ">
            <h4 className="font-bold text-lg mb-4">Comments</h4>
            <button
              className="text-blue-600 px-4 py-2 mb-4 rounded"
              onClick={toggleCommentsVisibility}
            >
              {commentsVisible ? "Hide Comments" : "View Comments"}
            </button>
            <div className="w-full max-h-96 overflow-y-auto mb-8 " ref={commentsRef}>
              {commentsVisible &&
                comments.map((comment: any) => (
                  <div key={`comment-${comment.id}`} className="mb-6">
                    <div className="comment flex mb-2">
                      {/* <div className="avatar mr-4">
                    <img
                      src={
                        loggedInUser?.profilePicture ||
                        "https://via.placeholder.com/40"
                      }
                      alt="avatar"
                      className="rounded-full h-10 w-10"
                    />
                  </div> */}
                      <div>
                        <p>{comment.user.firstName + ' ' + comment.user.lastName}</p>
                        <p className="text-gray-700 mb-1">{comment.comment}</p>
                        <div className="actions text-gray-500 text-sm flex-row flex items-center">
                          {/* <p className="mr-4">
                        {calculateTimeDifference(comment.timestamp)}
                      </p> */}


                          <div className="mt-4 mr-4 relative flex space-x-4 justify-start">
                            {/* Selected or default emoji */}
                            <div
                              onMouseEnter={() => handleMouseEnter(comment.id)}
                              onMouseLeave={() => handleMouseLeave(comment.id)}
                              className="cursor-pointer text-xl flex items-center space-x-2 relative"
                            >
                              {selectedEmojis[comment.id] ? (
                                renderSelectedEmoji(comment.id)
                              ) : (
                                <FaThumbsUp className="text-gray-400" />
                              )}

                              <span style={{
                                fontSize: '16px',
                                fontWeight: '600',
                                color: '#4a4a4a',
                                fontFamily: 'Roboto, Arial, sans-serif',
                                padding: '5px 10px',
                                borderRadius: '8px',
                                backgroundColor: '#f0f0f0',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                              }}>
                                {countReactions(comment.id)}
                              </span>
                              {/* Emoji reactions popup */}
                              {showReactions[comment.id] && (
                                <div
                                  className="absolute flex space-x-2 bg-white border rounded-lg p-2 shadow-lg"
                                  style={{ top: '-40px', left: '0px' }}
                                >
                                  <div className="flex flex-col items-center">
                                    <FaThumbsUp
                                      className="text-blue-600 cursor-pointer text-2xl"
                                      onClick={() => handleReaction(comment.id, 1)} // Thumbs up reaction
                                    />
                                    <span
                                      className="text-xs">{reactions[comment.id]?.one || 0}</span> {/* Count for thumbs up */}
                                  </div>

                                  <div className="flex flex-col items-center">
                                    <FaHeart
                                      className="text-red-600 cursor-pointer text-2xl"
                                      onClick={() => handleReaction(comment.id, 2)} // Heart reaction
                                    />
                                    <span className="text-xs">{reactions[comment.id]?.two || 0}</span> {/* Count for heart */}
                                  </div>

                                  <div className="flex flex-col items-center">
                                    <FaSmile
                                      className="text-yellow-600 cursor-pointer text-2xl"
                                      onClick={() => handleReaction(comment.id, 3)} // Smile reaction
                                    />
                                    <span className="text-xs">{reactions[comment.id]?.three || 0}</span> {/* Count for smile */}
                                  </div>

                                  <div className="flex flex-col items-center">
                                    <FaAngry
                                      className="text-orange-600 cursor-pointer text-2xl"
                                      onClick={() => handleReaction(comment.id, 4)} // Angry reaction
                                    />
                                    <span className="text-xs">{reactions[comment.id]?.four || 0}</span> {/* Count for angry */}
                                  </div>

                                  <div className="flex flex-col items-center">
                                    <FaSadTear
                                      className="text-purple-600 cursor-pointer text-2xl"
                                      onClick={() => handleReaction(comment.id, 5)} // Sad reaction
                                    />
                                    <span className="text-xs">{reactions[comment.id]?.five || 0}</span>
                                  </div>
                                </div>

                              )}
                            </div>
                          </div>

                          <button onClick={() => handleReplyClick(comment.id)} className="ml-2 mt-4">
                            Reply
                          </button>
                        </div>


                      {comment.children.length > 0 && (
                          <div style={{ marginTop: '1rem' }} className="replies">
                            {comment.children.length > 0 && (
                                <div
                                    style={{
                                      backgroundColor: '#f7f7f7',
                                      padding: '1rem',
                                      borderRadius: '0.5rem',
                                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                    }}
                                    className="replies-container"
                                >
                                  {comment.children.map((reply: any) => (
                                      <div
                                          key={`reply-${reply.id}`}
                                          style={{
                                            marginBottom: '1rem',
                                            paddingBottom: '0.5rem',
                                            borderBottom: '1px solid #ccc',
                                          }}
                                          className="reply"
                                      >
                                        <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'flex-start',
                                              marginBottom: '0.5rem',
                                            }}
                                        >
                                          {/* User Info */}
                                          <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                marginRight: '1rem',
                                              }}
                                          >
                                            <p
                                                style={{
                                                  fontWeight: '600',
                                                  fontSize: '1rem',
                                                  color: '#333',
                                                }}
                                            >
                                              {reply.user.firstName + ' ' + reply.user.lastName}
                                            </p>
                                            <p
                                                style={{
                                                  fontSize: '0.875rem',
                                                  color: '#666',
                                                }}
                                            >
                                              {reply.comment}
                                            </p>
                                            <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'space-between',
                                                  marginTop: '0.5rem',
                                                }}
                                                className="actions"
                                            >
                                              <div className="mt-4 mr-4 relative flex space-x-4 justify-start">
                                                {/* Selected or default emoji */}
                                                <div
                                                    onMouseEnter={() => handleMouseEnter(reply.id)}
                                                    onMouseLeave={() => handleMouseLeave(reply.id)}
                                                    className="cursor-pointer text-xl flex items-center space-x-2 relative"
                                                >
                                                  {selectedEmojis[reply.id] ? (
                                                      renderSelectedEmoji(reply.id)
                                                  ) : (
                                                      <FaThumbsUp className="text-gray-400"/>
                                                  )}

                                                  <span style={{
                                                    fontSize: '16px',
                                                    fontWeight: '600',
                                                    color: '#4a4a4a',
                                                    fontFamily: 'Roboto, Arial, sans-serif',
                                                    padding: '5px 10px',
                                                    borderRadius: '8px',
                                                    backgroundColor: '#f0f0f0',
                                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                                  }}>
                                                  {countReactions(reply.id)}
                        </span>
                                                  {/* Emoji reactions popup */}
                                                  {showReactions[reply.id] && (
                                                      <div
                                                          className="absolute flex space-x-2 bg-white border rounded-lg p-2 shadow-lg"
                                                          style={{top: '-40px', left: '0px'}}
                                                      >
                                                        <div className="flex flex-col items-center">
                                                          <FaThumbsUp
                                                              className="text-blue-600 cursor-pointer text-2xl"
                                                              onClick={() => handleReaction(reply.id, 1)} // Thumbs up reaction
                                                          />
                                                          <span
                                                              className="text-xs">{reactions[reply.id]?.one || 0}</span> {/* Count for thumbs up */}
                                                        </div>

                                                        <div className="flex flex-col items-center">
                                                          <FaHeart
                                                              className="text-red-600 cursor-pointer text-2xl"
                                                              onClick={() => handleReaction(reply.id, 2)} // Heart reaction
                                                          />
                                                          <span
                                                              className="text-xs">{reactions[reply.id]?.two || 0}</span> {/* Count for heart */}
                                                        </div>

                                                        <div className="flex flex-col items-center">
                                                          <FaSmile
                                                              className="text-yellow-600 cursor-pointer text-2xl"
                                                              onClick={() => handleReaction(reply.id, 3)} // Smile reaction
                                                          />
                                                          <span
                                                              className="text-xs">{reactions[reply.id]?.three || 0}</span> {/* Count for smile */}
                                                        </div>

                                                        <div className="flex flex-col items-center">
                                                          <FaAngry
                                                              className="text-orange-600 cursor-pointer text-2xl"
                                                              onClick={() => handleReaction(reply.id, 4)} // Angry reaction
                                                          />
                                                          <span
                                                              className="text-xs">{reactions[reply.id]?.four || 0}</span> {/* Count for angry */}
                                                        </div>

                                                        <div className="flex flex-col items-center">
                                                          <FaSadTear
                                                              className="text-purple-600 cursor-pointer text-2xl"
                                                              onClick={() => handleReaction(reply.id, 5)} // Sad reaction
                                                          />
                                                          <span
                                                              className="text-xs">{reactions[reply.id]?.five || 0}</span>
                                                        </div>
                                                      </div>

                                                  )}
                                                </div>
                                              </div>
                                              <button
                                                  onClick={() => handleReplyCommentReplyClick(comment.id,reply.id)}
                                                  style={{
                                                    color: '#3498db',
                                                    cursor: 'pointer',
                                                  }}
                                              >
                                                Reply
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  ))}
                                </div>
                            )}
                          </div>
                      )}

                    </div>
                  </div>
                {/* ))} */}

              {isLoading && <div>
                {/* <CategoryNavBar isDropdownOpen={isDropdownOpen} handleDropdownToggle={handleDropdownToggle} /> */}
                <div className="h-32 max-w-sm w-full flex justify-start items-center ">
                  <div className="w-full h-full flex flex-col items-start">
                    <div className="animate-pulse bg-blue-500 shadow rounded-full h-4 w-full mx-auto mb-2">
                    </div>
                    <div className="flex gap-2 h-4 w-full mb-2">
                      <div className="animate-pulse bg-blue-500 shadow rounded-full h-4 w-1/2 mx-auto">
                      </div>
                      <div className="animate-pulse bg-blue-500 shadow rounded-full h-4 w-1/2 mx-auto">
                      </div>
                    </div>
                    <div className="animate-pulse bg-blue-500 shadow rounded-full h-4 w-full  mb-2">
                    </div>


                  </div>
                </div>
              </div>
              }
            </div>
                ))}
          </div>
          


          {/* Comment & Reply Input */}
          <div className="flex mb-6 w-1/2">
            <input
                type="text"
                className="flex-grow border-b p-2 rounded mb-2 mr-2"
                placeholder={
                  replyTo ? `Replying to ${commentReplyUser}` : "Add a comment"

                }
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                onClick={handleInputClick} // Trigger login modal when input is clicked

              />
              <button
                className="bg-blue-500 text-white px-4 py-1 rounded"
                onClick={addCommentOrReply} // Handle both comments and replies
              >
                <i className="bi bi-send-fill"></i>
              </button>
            </div>

          </div>
        </div>
        <LoginModal
          show={showLoginModal}
          handleClose={() => setShowLoginModal(false)}
        />
      
      <LoginModal
          show={showLoginModal}
          handleClose={() => setShowLoginModal(false)}
      />
    </div>
</>
  );
  
}