import React, { useEffect, useState } from "react";

import SideBar from "./SideBar";
import ItemCard from "../../component/card/ItemCard";
import FilterBrandBar from "./FilterBrandBar";
import FilterPriceBar from "./FilterPriceBar";
import FilterAgeBar from "./FilterAgeBar";
import FilterPetSizeBar from "./FilterPetSizeBar";
import FilterActivityType from "./FilterActivityTypeBar";
import axios from "axios";
import { useParams } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import CartView from "./CartView";
import CategoryNavBar from "./CategoryNavBar";
import { Pagination, Typography } from "@mui/material";
import { log } from "console";
import { Helmet } from "react-helmet";

const baseUrl = process.env.REACT_APP_BASE_URL;

interface CartItem {
  image: string;
  name: string;
  price: string;
}

interface Item {
  id: number;
  itemId: number;
  itemName: string;
  imgURL: string;
  batchId: number;
  price: number;
  isWish?: boolean;
}

interface WishlistItem {
  id: number;
  isAvailable: boolean;
  itemId: number;
  itemName: string;
  imgURL: string;
  batchId: number;
  price: number;
}

const ShoppingCartItem = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [noItems, setNoItems] = useState(false);
  const { companyId, categoryId, brandId } = useParams();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(16);
  const [orderBy, setOrderBy] = useState("ASC");
  const [isDropdownOpen, setDropdownOpen] = useState(true);
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [isClick, setIsClick] = useState(false);

  const [wishlist, setWishlist] = useState<WishlistItem[]>([]);
  const [updateItem, setUpdateItem] = useState<Item[]>([]);

  //get items with pagination, brand and category
  const fetchItems = () => {
    window.scrollTo(0, 0);
    const newSearchKey = brandId ? "BY_CATEGORY_BRAND" : "BY_CATEGORY";
    const url = `${baseUrl}/webItem/super_search`;

    const data = {
      companyId: companyId,
      categoryId: categoryId,
      searchKey: newSearchKey,
      brandId: brandId || 0,
      itemId: 0,
      batchId: 0,
      orderBy: orderBy,
      paginationDTO: {
        offset: page - 1,
        limit: rowsPerPage,
        columnName: "id",
      },
    };

    axios
      .post(url, data)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          const items = response.data.data.items || [];
          setItems(items);
          setRowCount(response.data.data.rowCount || 0);
          setNoItems(items.length === 0);
        } else {
          setItems([]);
          setRowCount(0);
          setNoItems(true);
          console.error("No items found in response");
        }
      })
      .catch((err) => {
        console.error(err);
        setItems([]);
        setRowCount(0);
        setNoItems(true);
      });
  };

  const fetchWishlist = () => {
    const user = JSON.parse(sessionStorage.getItem("user") || "{}");
    const userId = user.id;
    const token = user.token;

    if (userId && token) {
      axios
        .get(`${baseUrl}/wishList/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            setWishlist(response.data.data.wishList || []);
          }
        })
        .catch((error) => {
          console.error("Error fetching wishlist data:", error);
        });
    }
  };
  console.log("wishlist :", wishlist);

  useEffect(() => {
    // const itemId = wishlist.map(items => items.itemId);
    // setWishlistItemId (itemId);
    const updateItem = items.map((item) => {
      const machingItem = wishlist.find((item2) => item2.itemId === item.id);
      if (machingItem) {
        return { ...item, isWish: true };
      } else {
        return { ...item, isWish: false };
      }
    });
    console.log("update items: ", updateItem);
    setUpdateItem(updateItem);
  }, [items, wishlist]);

  //
  useEffect(() => {
    fetchWishlist();
    fetchItems();
  }, [companyId, categoryId, brandId, page, rowsPerPage, orderBy]);

  //
  useEffect(() => {
    setPage(1);
    setRowsPerPage(16);
  }, [companyId, categoryId, brandId]);

  //slide bar hight change
  useEffect(() => {
    const handleResize = () => {
      setSidebarVisible(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //show or hide slide bar
  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
    setSidebarVisible(!isSidebarVisible);
  };

  const handleCartClick = () => {
    console.log("hii");
  };

  // for pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 16));
    setPage(1);
  };

  //Change the order by triggering this method
  const handleOrderByChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setOrderBy(event.target.value);
    setPage(1);
  };

  //item add cart
  const addItemToCart = () => {
    setIsClick(!isClick);
  };

  const isItemInWishlist = (itemId: number) => {
    return wishlist.some((wishItem) => wishItem.itemId === itemId);
  };

  return (
    <>
      {/* <Helmet>
        <title>Pet Medi Web - Checkout Page</title>
        <meta
          name="description"
          content="Learn more about Pet Medi, your trusted pet health partner."
        />
        <meta
          name="keywords"
          content="pet, health, care, dogs, cats, veterinary,Pet clinic in Sri Lanka,Veterinary services Sri Lanka,Pet hospital,Affordable pet care Sri Lanka, 
      Veterinarian near me,Animal clinic Sri Lanka,Pet surgery ,Pet vaccinations Sri Lanka,24/7 emergency vet Sri Lanka, Pet health checkup Sri Lanka, Dog clinic Sri Lanka, 
      Cat veterinary care Sri Lanka, Exotic pet care Sri Lanka, Pet dental care Sri Lanka, Pet grooming, Pet boarding services Sri Lanka, Best vet for pet Sri Lanka, 
      Cataract surgery for pets Sri Lanka, Animal rescue services Sri Lanka ,Veterinary clinic , Sri Lanka , Pet Medi, pet, P , Sri Lanka Pets, Pet Care, Animal Clinic, 
      Vet, Pet Health, Pet Health Checkup, veterinary,Pet dental care, Grooming, Best Animal Clinic, Pet health monitoring app, Veterinary telemedicine Sri Lanka,Pet prescription management,
      Pet weight management Sri Lanka,Pet nutrition advice Sri Lanka,Digital veterinary solutions,Pet clinic appointment system,Senior pet care services,Specialized pet care Sri Lanka,
      Online vet consultation Sri Lanka,Pet illness prevention,Remote vet consultations,Pet healthcare software,Preventive vet care Sri Lanka,Pet emergency hotline Sri Lanka,
      Advanced pet diagnostics,Pet care plans Sri Lanka,In-home veterinary services,Pet surgery aftercare Sri Lanka,Pet grooming and spa Sri Lanka,Veterinary lab testing Sri Lanka,
      Personalized pet healthcare plans,Vet treatment packages Sri Lanka,Animal health solutions Sri Lanka,Vet treatment plans Sri Lanka,Pet physiotherapy Sri Lanka, 
      Vets near me, cat clinic near me, dog clinic near me, rabbit consultant doctors, dog consultant doctors, cat consultant doctors, online pet app, pet app online, 
      app for pets, app for animals, animal doctor, pet doctor, pet consultant, Pet wellness programs Sri Lanka, Pet wellness programs, Veterinary nutrition consultation, 
      Pet behavior training Sri Lanka, Pet behavior training, Veterinary dermatology Sri Lanka, Veterinary dermatology, Puppy vaccinations, Puppy vaccinations Sri Lanka, 
      Kitten vaccinations Sri Lanka, Kitten vaccinations, Flea and tick prevention Sri Lanka, Flea and tick prevention, Microchipping services Sri Lanka, Pet allergy treatment Sri Lanka, Pet allergy treatment,
      Veterinary orthopedic surgery Sri Lanka,Veterinary orthopedic surgery, 24-hour vet service Sri Lanka, 24-hour vet service , Emergency pet care Sri Lanka, Emergency pet care,
      Animal eye care Sri Lanka,Animal eye care,Pet dental cleaning Sri Lanka,Pet dental cleaning,Dog vaccinations Sri Lanka, Dog vaccinations,Cat vaccinations Sri Lanka,Cat vaccinations,Animal diagnostic imaging,
      Vet appointment booking online Sri Lanka,Vet appointment booking online,Pet Surgery Sri Lanka, Pet Surgery,Specialist vet services Sri Lanka,Specialist vet services,Pet cardiologist Sri Lanka, Pet cardiologist,
      Best dog vet Sri Lanka,Best dog vet, Best cat vet Sri Lanka, Best cat vet,Pet health checkup Sri Lanka, Pet health checkup, Puppy health checkup Sri Lanka,Puppy health checkup, Kitten health checkup Sri Lanka,Kitten health checkup,
      Emergency pet clinic near me,Pet health and wellness Sri Lanka,Pet health and wellness , pet treatment,Pet Health, Pet Services,Pet Services near me,Pet health diagnosis,Veterinary diagnosis,Common pet diseases,Animal health conditions,
      Dog illness diagnosis,Cat health problem, Dog health problem, Pet health problem, Pet disease symptoms, Veterinary medical conditions, Signs of illness in pets, Pet diagnostic tests,
      Dog allergy diagnosis, Cat urinary tract infection symptoms, Dog urinary tract infection symptoms, Pet urinary tract infection symptoms, Veterinary blood tests for pets, Pet arthritis treatment,
      Dog diabetes diagnosis, Cat diabetes diagnosis, Pet diabetes diagnosis, Pet cancer signs, Common pet skin diseases, Animal ear infections, Dog digestive problems diagnosis,
      Cat digestive problems diagnosis, Pet digestive problems diagnosis, Pet liver disease symptoms, Kidney disease in cats, Kidney disease in dogs, Kidney disease in pets,
      Pet obesity treatment, Dog heart disease symptoms, Cat heart disease symptoms, Pet heart disease symptoms, Cat thyroid disease, Dog thyroid disease, Pet thyroid disease,
      Pet parasite infections, Veterinary checkup for pets, Dog hip dysplasia diagnosis, Cat respiratory infections, Dog respiratory infections, Pet respiratory infections, Pancreatitis in pet symptoms, Dog Food, Cat Food"
    />
      </Helmet> */}

      <div>
        <CategoryNavBar
          isDropdownOpen={isDropdownOpen}
          handleDropdownToggle={handleDropdownToggle}
          isClick={isClick}
          handleCartClick={handleCartClick}
        />
        <div className="flex flex-col md:flex-row gap-4 px-6 sm:px-12 lg:px-16 bg-gray-100 pb-4">
          {isSidebarVisible && (
            <div className="w-full md:w-56">
              <SideBar height="420px" />
              <h2 className="mt-5 ml-5 font-medium text-2xl">Filters</h2>
              <h3 className="mt-5 ml-5 -mb-5 text-base">Brands</h3>
              <FilterBrandBar />
              <h3 className="mt-5 ml-5 -mb-5 text-base">Price</h3>
              <FilterPriceBar />
              <h3 className="mt-5 ml-5 -mb-5 text-base">Age</h3>
              <FilterAgeBar />
              <h3 className="mt-5 ml-5 -mb-5 text-base">Pet Size</h3>
              <FilterPetSizeBar />
              <h3 className="mt-5 ml-5 -mb-5 text-base">Activity Type</h3>
              <FilterActivityType />
            </div>
          )}
          <div className="w-full pt-5">
            <div className="mb-4 flex justify-end items-center">
              <span className="mr-2">Sort By:</span>
              <div className="relative">
                <select
                  className="appearance-none border cursor-pointer border-gray-300 rounded py-2 pl-3 pr-8 leading-tight focus:outline-none focus:border-blue-500"
                  value={orderBy}
                  onChange={handleOrderByChange}
                >
                  <option value="ASC">Price Low To High</option>
                  <option value="DESC">Price High To Low</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                  </svg>
                </div>
              </div>
            </div>
            {noItems ? (
              <div className="text-center text-gray-500 mt-20">
                No items available.
              </div>
            ) : (
              <div
                className={`grid gap-4 ${isSidebarVisible
                    ? "grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3"
                    : "grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4"
                  }`}
              >
                {updateItem.map((slide: any, index) => (
                  <ItemCard
                    key={index}
                    addItemToCart={addItemToCart}
                    slide={slide}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-center items-center bg-gray-100 p-4 sm:pb-4">
          <Pagination
            count={Math.ceil(rowCount / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
            showFirstButton
            showLastButton
            classes={{ ul: "flex justify-center" }}
          />
          <span className="text-blue-600 mb-2 sm:mb-0 sm:mr-4">
            Page: {page}
          </span>
        </div>
      </div>
    </>
  );
};

export default ShoppingCartItem;
