import React from 'react';

interface Address {
  id: number | null;
  fullName: string;
  mobileNumber: string;
  address: string;
  city: string;
  district: string;
  defaultAddress: boolean;
  webUserId: number;
}

interface AddressSectionProps {
  addressData?: Address[]; 
  defaultAddress?: Address;
  openModal: () => void;
  setNewAddress: (value: boolean) => void;
  setChangeAddress: (value: boolean) => void;
  setEditAddress: (value: boolean) => void;
}

const AddressSection: React.FC<AddressSectionProps> = ({
  addressData = [], 
  defaultAddress,
  openModal,
  setNewAddress,
  setChangeAddress,
  setEditAddress
}) => {
  return (
    <div className="bg-white shadow rounded-md mb-2">
      {addressData.length === 0 ? (
        <div className="py-6 flex items-center justify-center">
          <button
            onClick={() => {
              setNewAddress(true);
              setEditAddress(false);
              setChangeAddress(false);
              openModal();
            }}
            className="text-blue-500 font-semibold flex items-center justify-center w-full"
          >
            + Add New Delivery Address
          </button>
        </div>
      ) : (
        <div>
          {defaultAddress ? (
            <div className="p-4 rounded-lg mb-4 flex justify-between">
              <div className="text-left">
                <p className="text-sm font-semibold">
                  Deliver to: {defaultAddress.fullName}
                </p>
                <p className="text-sm">{defaultAddress.mobileNumber}</p>
                <p className="text-sm">
                  {defaultAddress.address}, {defaultAddress.city},
                </p>
                <p className="text-sm">{defaultAddress.district}.</p>
              </div>
              <div className="text-right ml-auto">
                <button
                  className="text-blue-500 text-sm"
                  onClick={() => {
                    setChangeAddress(true);
                    setNewAddress(false);
                    setEditAddress(false);
                    openModal();
                    window.scrollTo(0, 0);
                  }}
                >
                  Change
                </button>
              </div>
            </div>
          ) : (
            <div className="p-4 rounded-lg mb-4 flex justify-between">
              <div className="text-left">
                <p className="text-sm font-semibold text-red-600">
                  Please Set Default Shopping Address!
                </p>
              </div>
              <div className="text-right ml-auto">
                <button
                  className="text-blue-500 text-sm"
                  onClick={() => {
                    setChangeAddress(true);
                    setNewAddress(false);
                    setEditAddress(false);
                    openModal();
                    window.scrollTo(0, 0);
                  }}
                >
                  edit
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AddressSection;
