import React, { Component } from 'react';
import { HashRouter, Link } from 'react-router-dom';
import "./Navbar.css"

interface DropdownProps {
  isAboutPage: boolean;
  isScrolled: boolean;
  isCustomerPage: boolean;
  isTermsPage: boolean;

}

interface DropdownState {
  isOpen: boolean;
}

export default class Dropdown extends Component<DropdownProps, DropdownState> {
  private dropdownRef: React.RefObject<HTMLDivElement>;

  constructor(props: DropdownProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.dropdownRef = React.createRef();
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event: MouseEvent) {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node)) {
      this.closeDropdown();
    }
  }

  toggleDropdown() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  closeDropdown() {
    this.setState({ isOpen: false });
  }

  render() {
    const { isAboutPage, isScrolled, isCustomerPage, isTermsPage } = this.props;
    const { isOpen } = this.state;
    const dropdownClasses = `${isScrolled ? 'dropdown-menu white-background' : 'dropdown-menu default-background'} shadow-lg rounded-lg w-64 `;

    return (
      <div className="relative" ref={this.dropdownRef}>

        <button
          onClick={this.toggleDropdown}
          className="text-lg focus:outline-none "
        >
          Pages
        </button>
        {isOpen && (
          <ul className={`absolute mt-2 ${dropdownClasses}`}>
            <li className="text-lg dropdown-item ">
              <Link to="/customer" onClick={this.closeDropdown} className={`block px-4 py-2 ${isAboutPage && isScrolled && isCustomerPage ? 'text-black' : ''}`}>
                Our-Customers
              </Link>
            </li>
            {/* <li className="text-lg dropdown-item">
              <Link to="/policy" onClick={this.closeDropdown} className={`block px-4 py-2 ${isAboutPage && isScrolled && isCustomerPage ? 'text-black' : ''}`}>
                Privacy-Policy
              </Link>
            </li>
            <li className="text-lg dropdown-item">
              <Link to="/terms" onClick={this.closeDropdown} className={`block px-4 py-2 ${isAboutPage && isScrolled && isCustomerPage ? 'text-black' : ''}`}>
                Terms & Conditions
              </Link>
            </li> */}
            <li className="text-lg dropdown-item">
              <Link to="/blog" onClick={this.closeDropdown} className={`block px-4 py-2 ${isAboutPage && isScrolled && isCustomerPage ? 'text-black' : ''}`}>
                Blog
              </Link>
            </li>
          </ul>

        )}
      </div>
    );
  }
}
