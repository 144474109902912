import React from 'react';
import Navbar from '../Navbar/Navbar';
import Main from '../MainContent/Main';
import Footer from '../Footer/Footer';

const DefaultLayout = () => {
  return (
    <>
      <Navbar hideNavbar={false} />
      <Main />
      <Footer />
    </>
  );
};

export default DefaultLayout;
