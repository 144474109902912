import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;

const SideBar = ({ height , handleClickCategory}: any) => {
  
  const [categories, setCategories] = useState([]);
  const { companyId } = useParams();

  useEffect(() => {
    axios.get(`${baseUrl}/webCategory/${companyId}`)
      .then(response => {
        if (response.status === 200) {
          setCategories(response.data.data.category);
        } 
      }).catch(err => {
        console.log(err);
      });
  }, [companyId]);

  return (
    <div className="pt-6">
      <div className="overflow-hidden rounded-2xl shadow-md w-full md:w-52">
        <ul
          style={{ height }}
          className="bg-white p-2 w-full overflow-y-auto scrollbar-thin scrollbar-thumb-blue-700 scrollbar-track-white"
        >
          {categories.map((item: any, index: number) => (
            <li key={index} className="py-1">
              <Link
              onClick={handleClickCategory}
                to={`/shoppingCartItem/${companyId}/${item.categoryId}`}
                className="flex items-center p-2 hover:bg-blue-100 rounded-md"
              >
                <span className="ml-2 text-sm">{item.name}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
