import React, { useState } from 'react';

const ConfirmPasswordPage: React.FC<{ onClose: () => void; onPasswordChange: (newPassword: string) => void; }> = ({ onClose, onPasswordChange }) => {
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [message, setMessage] = useState<string | null>(null);
    const [passwordStrength, setPasswordStrength] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const passwordPattern = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d)(?=[^!@#$%^&*()_+[\]{};':"\\|,.<>/?`~\-]*[!@#$%^&*()_+[\]{};':"\\|,.<>/?`~\-]).{8,}$/;

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match.');
            setPasswordError('Passwords must match.');
            return;
        }

        if (passwordError) {
            return;
        }

        setLoading(true);
        await onPasswordChange(newPassword);
        setMessage('Password has been changed successfully!');
        setLoading(false);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        setNewPassword(password);
        setConfirmPassword('');
        setPasswordError(null);

        if (!passwordPattern.test(password)) {
            setPasswordStrength('Weak');
            setPasswordError('Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one digit, and one special character.');
        } else {
            if (password.length < 10) {
                setPasswordStrength('Moderate');
            } else {
                setPasswordStrength('Strong');
            }
            setPasswordError(null);
        }
    };

    return (
        <div>
            <h2 className="text-2xl font-bold">Set New Password</h2>
            <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">New Password</label>
                    <input
                        type="password"
                        id="newPassword"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-blue-600 transition duration-300 ease-in-out"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={handlePasswordChange}
                        required
                    />
                    {passwordStrength && (
                        <div className={`text-sm ${passwordStrength === 'Weak' ? 'text-red-500' : passwordStrength === 'Moderate' ? 'text-yellow-500' : 'text-green-500'}`}>
                            Password Strength: {passwordStrength}
                        </div>
                    )}
                    {passwordError && (
                        <div className="text-red-500 text-sm">{passwordError}</div>
                    )}
                </div>

                <div>
                    <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-blue-600 transition duration-300 ease-in-out"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>

                {message && (
                    <div className="text-green-500 text-sm">{message}</div>
                )}

                <button
                    type="submit"
                    className={`w-full bg-blue-600 text-white py-2 px-4 mt-5 rounded hover:bg-blue-700 transition duration-300 ease-in-out ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
                    disabled={loading}
                >
                    {loading ? 'Changing...' : 'Change Password'}
                </button>
                <button
                    type="button"
                    onClick={onClose}
                    className="text-blue-600 hover:underline mt-2"
                >
                    Back to Login
                </button>
            </form>
        </div>
    );
};

export default ConfirmPasswordPage;
